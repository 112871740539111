/*------------------------------------*\
    #Call To Action
\*------------------------------------*/
.cta {
    position: relative;
    overflow: visible;
    padding : 85px 0;

    .container {
        padding-top   : 60px;
        padding-bottom: 60px;

        @media #{$maxTablet} {
            padding-top   : 65px;
            padding-bottom: 65px;
        }
    }
}

.form-mailchimp {
    position     : relative;
    margin-right : 0;
    margin-left  : 0;
    margin-bottom: 6px;

    .input-group {
        display         : flex;
        justify-content : space-between;
        border          : none;
        height          : 60px;
        padding         : 0;
        border-radius   : 4px;
        background-color: var(--global--color-white);
        position        : relative;
        overflow        : hidden;

        @media #{$maxSmall} {
            flex-direction: column;
            height        : auto;
        }

        .btn {
            width        : 200px;
            border-radius: 0 4px 4px 0;
            height       : 60px;
            line-height  : 60px;
            outline      : none;

            @media #{$maxSmall} {
                border-radius: 4px;
                margin-left  : auto;
                margin-right : auto;
            }
        }

        .input-icon {
            display    : flex;
            align-items: center;
            flex-grow  : 1;

            .form-control {
                margin-bottom   : 0;
                border-bottom   : 0;
                height          : 60px;
                padding-left    : 30px;
                padding-right   : 20px;
                line-height     : normal;
                font-size       : 16px;
                font-weight     : 500;
                font-family     : var(--global--font-body);
                text-transform  : capitalize;
                color           : var(--global--color-body);
                background-color: #f5f5f5;

                @media #{$maxSmall} {
                    margin-bottom: 30px;
                }
            }
        }
    }
}

.cta-note {
    text-align: center;

    p {
        margin-bottom: 0;
        font-family  : var(--global--font-body);
        font-weight  : 400;
        font-size    : 16px;
        line-height  : 24px;
        color        : var(--global--color-body);

        i {
            margin-right: 5px;
        }
    }
}