@mixin rounded($radius: 2px) {
	-webkit-border-radius: $radius;
	-moz-border-radius   : $radius;
	border-radius        : $radius;
}

@mixin opacity($opacity: 0.5) {
	-moz-opacity   : $opacity;
	-khtml-opacity : $opacity;
	-webkit-opacity: $opacity;
	opacity        : $opacity;
	$opperc        : $opacity * 100;
	-ms-filter     : "progid:DXImageTransform@mixin Microsoft@mixin Alpha(opacity=${opperc})";
	filter         : "alpha(opacity=${opperc})";
}

@mixin transition($duration:0.3s, $ease:ease-out) {
	-webkit-transition: all $duration $ease;
	-moz-transition   : all $duration $ease;
	-o-transition     : all $duration $ease;
	transition        : all $duration $ease;
}


@mixin keyFrame($animationName) {
	@-webkit-keyframes #{$animationName} {
		@content;
	}

	@-moz-keyframes #{$animationName} {
		@content;
	}

	@keyframes #{$animationName} {
		@content;
	}
}