/*------------------------------------*\
    #Modules
\*------------------------------------*/
.header {
    .module-container {
        @media #{$minLarge} {
            margin-left : 30px;
            padding-left: 30px;
            position    : relative;

            &::before {
                content         : '';
                position        : absolute;
                top             : 50%;
                transform       : translateY(-50%);
                left            : 0;
                width           : 1px;
                height          : 20px;
                background-color: #ffffff82;
            }
        }

        @media #{$maxTablet} {
            padding-left : 10px;
            margin-top   : 0px;
            margin-bottom: 10px;
        }

        .module-social {
            a {
                margin-right: 35px;

                &:last-child {
                    margin-right: 0;
                }

                i {
                    color     : var(--global--color-white);
                    transition: 0.3s ease-in-out;
                    display   : inline-flex;

                    @media screen and (max-width:991px) {
                        color: var(--global--color-heading);
                    }

                    &:hover {
                        color    : var(--global--color-primary);
                        transform: translateY(-3px);
                    }
                }
            }
        }
    }

    .navbar-fixed {
        .module-container {
            @media #{$minLarge} {
                &::before {
                    background-color: #00000080;
                }
            }

            .module-social {
                a {
                    i {
                        color: var(--global--color-heading);

                        &:hover {
                            color: var(--global--color-primary);
                        }
                    }
                }
            }
        }
    }
}