/*-----------------------------------------------------------------------------------

	Theme Name : Magma
	Theme URI  : http://themeforest.net/user/zytheme
	Description: Magma is a pixel perfect creative HTML5 landing page  based on designed with great attention to details, flexibility and performance. It is ultra professional, smooth and sleek, with a clean modern layout.
	Author     : zytheme
	Author URI : http://themeforest.net/user/zytheme
	Version    : 1.0

-----------------------------------------------------------------------------------*/
/*!
// Contents
// ------------------------------------------------>

1.  Global Styles
2.  Typography
3.	Color
4.	Align
5.	Grid
6.	Background
7.	Buttons
8.	Forms
9.	Heading
10. Header
11. Menu
12. Clients
13. Hero
14. Services
15. Testimonial
16. Feature
17. Action
18. Pricing
19. Carousel
20. Landing

/*------------------------------------*\
    #GLOBAL STYLES
\*------------------------------------*/
/*
WARNING! DO NOT EDIT THIS FILE!

To make it easy to update your theme, you should not edit the styles in this file. Instead use 
the custom.css file to add your styles. You can copy a style from this file and paste it in 
custom.css and it will override the style in this file. You have been warned! :)
*/
:root {
  --global--font-body: 'Roboto', sans-serif;
  --global--font-heading: 'Jost', sans-serif;
  --global--color-primary: #ff5421;
  --global--color-secondary: #f13700fc;
  --global--color-body: #999999;
  --global--color-heading: #333;
  --global--color-subheading: #fefeff;
  --global--color-white: #fff;
  --global--color-gray: #FAF8FB;
  --global--color-pink: #FEFBFF; }

body,
html {
  overflow-x: hidden; }

html {
  font-size: 13px; }

body {
  background-color: var(--global--color-white);
  font-family: var(--global--font-body);
  font-size: 13px;
  font-weight: 400;
  color: var(--global--color-body);
  line-height: 1.5;
  margin: 0; }

.wrapper {
  background-color: var(--global--color-white); }

* {
  outline: none; }

::-moz-selection {
  text-shadow: none; }

::selection {
  background-color: var(--global--color-primary);
  color: var(--global--color-white);
  text-shadow: none; }

a {
  transition: 0.3s ease;
  color: var(--global--color-primary); }
  a:hover {
    color: rgba(var(--global--color-primary), 10%);
    text-decoration: none; }
  a:focus {
    color: rgba(var(--global--color-primary), 10%);
    outline: none;
    text-decoration: none; }
  a:active {
    color: rgba(var(--global--color-primary), 10%);
    outline: none;
    text-decoration: none; }

hr {
  margin: 0; }

textarea {
  resize: none; }

button.btn {
  margin-bottom: 0; }

section {
  background-color: var(--global--color-white); }

.modal-backdrop {
  z-index: 1020;
  background-color: rgba(34, 34, 34, 0.95); }

.fullscreen {
  height: 100vh !important; }

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .fullscreen {
    height: auto !important; } }

/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/
/* Heading Text */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--global--color-heading);
  font-family: var(--global--font-heading);
  font-weight: 700;
  margin: 0 0 28px;
  line-height: 1; }

.h1,
h1 {
  font-size: 52px; }

.h2,
h2 {
  font-size: 42px; }

.h3,
h3 {
  font-size: 38px; }

.h4,
h4 {
  font-size: 32px; }

.h5,
h5 {
  font-size: 24px; }

.h6,
h6 {
  font-size: 18px; }

p {
  color: var(--global--color-body);
  font-size: 13px;
  font-weight: 400;
  line-height: 22px; }

.lead {
  font-size: 16px;
  line-height: 1.8; }

/* Aligning Text */
.text--left {
  text-align: left !important; }

.text--right {
  text-align: right !important; }

.text--center {
  text-align: center !important; }

.text--just {
  text-align: justify !important; }

.align--top {
  vertical-align: top; }

.align--bottom {
  vertical-align: bottom; }

.align--middle {
  vertical-align: middle; }

.align--baseline {
  vertical-align: baseline; }

/* Weight Text */
.bold {
  font-weight: bold; }

.regular {
  font-weight: normal; }

.italic {
  font-style: italic; }

.break-word {
  word-wrap: break-word; }

.no-wrap {
  white-space: nowrap; }

/* Text Color */
.text-white {
  color: var(--global--color-white) !important; }

.text-gray {
  color: --global--color-gray !important; }

.text-black {
  color: var(--global--color-heading); }

.text-theme {
  color: var(--global--color-primary); }

.text--capitalize {
  text-transform: capitalize !important; }

.text--uppercase {
  text-transform: uppercase !important; }

.font-heading {
  font-family: var(--global--font-heading); }

.font-body {
  font-family: var(--global--font-body); }

.font-18 {
  font-size: 24px; }

.font-16 {
  font-size: 16px; }

.font-20 {
  font-size: 20px; }

.font-40 {
  font-size: 40px; }

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  text-center-xs {
    text-align: center !important; } }

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  text-center-sm {
    text-align: center !important; } }

.higlighted-style1 {
  background-color: var(--global--color-primary);
  color: #fff;
  padding: 5px 0; }

.higlighted-style2 {
  background-color: var(--global--color-heading);
  color: #fff;
  padding: 5px 0; }

.higlighted-style3 {
  background-color: --global--color-gray;
  color: var(--global--color-primary);
  padding: 5px 0; }

/*------------------------------------*\
    #COLORS
\*------------------------------------*/
.color-heading {
  color: var(--global--color-heading) !important; }

.color-theme {
  color: var(--global--color-primary) !important; }

.color-white {
  color: var(--global--color-white) !important; }

.color-gray {
  color: --global--color-gray !important; }

/*------------------------------------*\
    #Align
\*------------------------------------*/
address,
blockquote,
dd,
dl,
fieldset,
form,
ol,
p,
pre,
table,
ul {
  margin-bottom: 20px; }

section {
  padding-top: 100px;
  padding-bottom: 100px;
  overflow: hidden; }
  @media screen and (max-width: 991px) {
    section {
      padding-top: 65px;
      padding-bottom: 65px; } }

/* All margin */
.m-0 {
  margin: 0 !important; }

.m-xs {
  margin: 10px; }

.m-sm {
  margin: 20px; }

.m-md {
  margin: 40px; }

.m-lg {
  margin: 80px; }

/* top margin */
.mt-0 {
  margin-top: 0; }

.mt-xs {
  margin-top: 10px; }

.mt-10 {
  margin-top: 10px !important; }

.mt-20 {
  margin-top: 20px !important; }

.mt-30 {
  margin-top: 30px !important; }

.mt-40 {
  margin-top: 40px !important; }

.mt-50 {
  margin-top: 50px !important; }

.mt-60 {
  margin-top: 60px; }

.mt-70 {
  margin-top: 70px !important; }

.mt-80 {
  margin-top: 80px !important; }

.mt-90 {
  margin-top: 90px !important; }

.mt-100 {
  margin-top: 100px !important; }

.mt-150 {
  margin-top: 150px; }

/* bottom margin */
.mb-0 {
  margin-bottom: 0 !important; }

.mb-15 {
  margin-bottom: 15px; }

.mb-10 {
  margin-bottom: 10px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.mb-50 {
  margin-bottom: 50px !important; }

.mb-40 {
  margin-bottom: 40px !important; }

.mb-60 {
  margin-bottom: 60px !important; }

.mb-70 {
  margin-bottom: 70px !important; }

.mb-80 {
  margin-bottom: 80px !important; }

.mb-90 {
  margin-bottom: 90px !important; }

.mb-100 {
  margin-bottom: 100px !important; }

.mb-150 {
  margin-bottom: 150px !important; }

/* right margin */
.mr-0 {
  margin-right: 0; }

.mr-30 {
  margin-right: 30px !important; }

.mr-50 {
  margin-right: 50px; }

.mr-60 {
  margin-right: 60px; }

.mr-150 {
  margin-right: 150px; }

/* left margin */
.ml-0 {
  margin-left: 0; }

.ml-xs {
  margin-left: 10px; }

.ml-sm {
  margin-left: 20px; }

.ml-md {
  margin-left: 40px; }

.ml-lg {
  margin-left: 80px; }

.ml-30 {
  margin-left: 30px !important; }

.ml-50 {
  margin-left: 50px; }

.ml-60 {
  margin-left: 60px; }

.ml-150 {
  margin-left: 150px; }

/* All padding */
.p-0 {
  padding: 0 !important; }

.p-xs {
  padding: 10px; }

.p-sm {
  padding: 20px; }

.p-md {
  padding: 40px; }

.p-lg {
  padding: 80px; }

/* top padding */
.pt-0 {
  padding-top: 0 !important; }

.pt-20 {
  padding-top: 20px !important; }

.pt-30 {
  padding-top: 30px !important; }

.pt-40 {
  padding-top: 40px !important; }

.pt-50 {
  padding-top: 50px; }

.pt-60 {
  padding-top: 60px; }

.pt-70 {
  padding-top: 70px !important; }

.pt-80 {
  padding-top: 80px; }

.pt-90 {
  padding-top: 90px; }

.pt-100 {
  padding-top: 100px !important; }

.pt-150 {
  padding-top: 150px !important; }

/* bottom padding */
.pb-0 {
  padding-bottom: 0 !important; }

.pb-20 {
  padding-bottom: 20px; }

.pb-30 {
  padding-bottom: 30px; }

.pb-50 {
  padding-bottom: 50px; }

.pb-60 {
  padding-bottom: 60px; }

.pb-70 {
  padding-bottom: 70px !important; }

.pb-80 {
  padding-bottom: 80px; }

.pb-90 {
  padding-bottom: 90px; }

.pb-100 {
  padding-bottom: 100px !important; }

/* right padding */
.pr-0 {
  padding-right: 0; }

.pr-xs {
  padding-right: 10px; }

.pr-sm {
  padding-right: 20px; }

.pr-md {
  padding-right: 40px; }

.pr-lg {
  padding-right: 80px; }

.pr-15 {
  padding-right: 15px !important; }

.pr-30 {
  padding-right: 30px !important; }

.pr-50 {
  padding-right: 50px; }

.pr-60 {
  padding-right: 60px; }

.pr-100 {
  padding-right: 100px !important; }

.pr-150 {
  padding-right: 150px; }

/* left padding */
.pl-0 {
  padding-left: 0 !important; }

.pl-30 {
  padding-left: 30px; }

.pl-50 {
  padding-left: 50px; }

.pl-60 {
  padding-left: 60px; }

.pl-100 {
  padding-left: 100px !important; }

.pl-150 {
  padding-left: 150px; }

/* Postions */
.fixed {
  position: fixed; }

.relative {
  position: relative; }

.absolute {
  position: absolute; }

.static {
  position: static; }

/* Zindex*/
.zindex-1 {
  z-index: 1; }

.zindex-2 {
  z-index: 2; }

.zindex-3 {
  z-index: 3; }

/* Borders */
.border-all {
  border: 1px solid var(--global--color-primary); }

.border-top {
  border-top: 1px solid var(--global--color-primary); }

.border-bottom {
  border-bottom: 1px solid var(--global--color-primary); }

.border-right {
  border-right: 1px solid var(--global--color-primary); }

.border-left {
  border-left: 1px solid var(--global--color-primary); }

/* Display */
.inline {
  display: inline; }

.block {
  display: block; }

.inline-block {
  display: inline-block; }

.hide {
  display: none; }

.flex {
  display: flex; }

@media only screen and (max-width: 767px) {
  section {
    padding-top: 60px;
    padding-bottom: 60px; }
  .text-center-xs {
    text-align: center !important; }
  .pull-none-xs {
    float: none !important;
    text-align: center !important; }
  .mb-15-xs {
    margin-bottom: 15px; }
  .mb-30-xs {
    margin-bottom: 30px !important; }
  .mb-50-xs {
    margin-bottom: 50px; }
  .mb-60-xs {
    margin-bottom: 60px !important; }
  .p-none-xs {
    padding-right: 0;
    padding-left: 0; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .text-center-sm {
    text-align: center !important; }
  .mb-0-sm {
    margin-bottom: 0; }
  .mb-15-sm {
    margin-bottom: 15px; }
  .mb-30-sm {
    margin-bottom: 30px !important; }
  .mb-50-sm {
    margin-bottom: 50px; }
  .mb-60-sm {
    margin-bottom: 60px; }
  .pb-15-sm {
    padding-bottom: 15px; }
  .pb-30-sm {
    padding-bottom: 30px; }
  .pb-50-sm {
    padding-bottom: 50px; }
  .pb-60-sm {
    padding-bottom: 60px; }
  .p-none-sm {
    padding-right: 0;
    padding-left: 0; }
  .pull-none-sm {
    float: none !important;
    text-align: center !important; } }

@media screen and (min-width: 992px) {
  .p-lg-60 {
    padding-right: 60px;
    padding-left: 60px; } }

/*------------------------------------*\
    #Grid
\*------------------------------------*/
.row-no-padding [class*="col-"] {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.col-lg-5ths,
.col-md-5ths,
.col-sm-5ths,
.col-xs-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col-xs-5ths {
  width: 20%;
  float: left; }

@media (min-width: 768px) {
  .col-sm-5ths {
    width: 20%;
    float: left; } }

@media (min-width: 992px) {
  .col-md-5ths {
    width: 20%;
    float: left; } }

@media (min-width: 1200px) {
  .col-lg-5ths {
    width: 20%;
    float: left; } }

.col-content {
  padding: 120px 100px 90px 120px; }

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .col-content {
    padding: 40px !important; } }

.col-img {
  height: 600px !important;
  padding: 60px;
  overflow: hidden; }

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .col-img {
    height: auto;
    min-height: 600px; } }

/* Postion Helpers */
.pos-fixed {
  position: fixed; }

.pos-relative {
  position: relative; }

.pos-absolute {
  position: absolute; }

.pos-static {
  position: static; }

.pos-top {
  top: 0; }

.pos-bottom {
  bottom: 0; }

.pos-right {
  right: 0; }

.pos-left {
  left: 0; }

.pos-vertical-center {
  position: relative;
  top: 50%;
  transform: perspective(1px) translateY(-50%); }

.height-700 {
  height: 700px !important; }

.height-500 {
  height: 500px !important; }

.height-800 {
  height: 800px !important; }

@media only screen and (min-width: 992px) {
  .hidden-lg,
  .hidden-md {
    display: none; } }

/*------------------------------------*\
    #BACKGROUNDS
\*------------------------------------*/
.bg-theme {
  background-color: var(--global--color-primary) !important; }

.bg-primary-alt {
  background-color: var(--color-hover) !important; }

.bg-gray {
  background-color: --global--color-gray !important; }

.bg-white {
  background-color: var(--global--color-white) !important; }

.bg-pink {
  background-color: var(--global--color-pink) !important;
  border-top: 1px solid #f8e2ff;
  border-bottom: 1px solid #f8e2ff; }

.bg-dark {
  background-color: var(--global--color-heading) !important; }

.bg-gradient {
  background: bottom center var(--global--color-white); }

.bg-light-primary {
  background-color: #F1F8FE !important; }

/* Background Image */
.bg-section {
  position: relative;
  overflow: hidden;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%; }

.bg-content {
  background-size: contain; }

.bg-overlay:before {
  content: "";
  display: inline-block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1; }

.bg-overlay-light:before {
  background-color: var(--global--color-white);
  opacity: 0.8; }

.bg-overlay-dark:before {
  background-color: black;
  opacity: 0.502; }

.bg-overlay-dark-2:before {
  background-color: black;
  opacity: 0.6; }

.bg-overlay-grey:before {
  background-color: whitesmoke;
  opacity: 0.902; }

.bg-overlay-theme:before {
  background-color: var(--global--color-primary);
  opacity: 0.7; }

.bg-parallax {
  background-attachment: fixed; }

.bg-ytvideo.bg-overlay:before {
  z-index: 1;
  opacity: .7; }

/* Background Color Palettes */
.bg-purple {
  color: var(--global--color-white);
  background-color: #cc66cc !important; }

.bg-sun-flower {
  color: var(--global--color-white);
  background-color: #f1c40f !important; }

.bg-orange {
  color: var(--global--color-white);
  background-color: #f39c12 !important; }

.bg-carrot {
  color: var(--global--color-white);
  background-color: #e67e22 !important; }

.bg-pumpkin {
  color: var(--global--color-white);
  background-color: #d35400 !important; }

.bg-alizarin {
  color: var(--global--color-white);
  background-color: #e74c3c !important; }

.bg-pomegranate {
  color: var(--global--color-white);
  background-color: #c0392b !important; }

.bg-turquoise {
  color: var(--global--color-white);
  background-color: #1abc9c !important; }

.bg-green-sea {
  color: var(--global--color-white);
  background-color: #16a085 !important; }

.bg-emerald {
  color: var(--global--color-white);
  background-color: #2ecc71 !important; }

.bg-nephritis {
  color: var(--global--color-white);
  background-color: #27ae60 !important; }

.bg-peter-river {
  color: var(--global--color-white);
  background-color: #3498db !important; }

.bg-belize-hole {
  color: var(--global--color-white);
  background-color: #2980b9 !important; }

.bg-amethyst {
  color: var(--global--color-white);
  background-color: #9b59b6 !important; }

.bg-wisteria {
  color: var(--global--color-white);
  background-color: #8e44ad !important; }

.bg-wet-asphalt {
  color: var(--global--color-white);
  background-color: #34495e !important; }

.bg-wet-asphalt {
  color: var(--global--color-white);
  background-color: #34495e !important; }

.bg-midnight-blue {
  color: var(--global--color-white);
  background-color: #2c3e50 !important; }

.bg-silver {
  color: var(--global--color-white);
  background-color: #bdc3c7 !important; }

.bg-concrete {
  color: var(--global--color-white);
  background-color: #859596 !important; }

.bg-asbestos {
  color: var(--global--color-white);
  background-color: #7f8c8d !important; }

.bg-asbestos {
  color: var(--global--color-white);
  background-color: #7f8c8d !important; }

.bg-graphite {
  color: var(--global--color-white);
  background-color: #454545 !important; }

.bg-gray-0 {
  background-color: #EEEEEE !important;
  color: #454545; }

.bg-gray-1 {
  background-color: #ECECEC !important;
  color: #454545; }

.bg-gray-2 {
  background-color: #BDC3C7 !important;
  color: #454545; }

.bg-gray-3 {
  background-color: #DADFE1 !important;
  color: #454545; }

.bg-gray-5 {
  background-color: #ECF0F1 !important;
  color: #454545; }

.bg-gray-4 {
  background-color: #F2F1EF !important;
  color: #454545; }

.bg-gray-6 {
  background-color: #D2D7D3 !important;
  color: #454545; }

.bg-gray-7 {
  background-color: #E6E6E6 !important;
  color: #454545; }

.bg-orange-1 {
  background-color: #F9690E !important;
  color: var(--global--color-white); }

.bg-orange-2 {
  background-color: #D35400 !important;
  color: var(--global--color-white); }

.bg-orange-3 {
  background-color: #F89406 !important;
  color: var(--global--color-white); }

.divider-1 {
  height: 1px;
  background-color: #fce5e6; }

.divider-2 {
  height: 1px;
  width: 100%;
  background-color: #f8e2ff; }

.skew-divider {
  width: 100%;
  height: 100px;
  position: absolute;
  left: 0px;
  background: linear-gradient(to right bottom, #FEFBFF 49%, #fff 50%), linear-gradient(-50deg, #ffffff 16px, #000 0); }
  .skew-divider.divider-theme {
    background: linear-gradient(to right bottom, var(--global--color-primary) 49%, #fff 50%), linear-gradient(-50deg, #ffffff 16px, #000 0); }

.skew-divider-top {
  width: 100%;
  height: 100px;
  position: absolute;
  left: 0px;
  top: 0;
  background: linear-gradient(to right bottom, #ffffff 49%, #262B47 50%), linear-gradient(-50deg, #ffffff 16px, #000 0); }

/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/
.btn {
  font-family: var(--global--font-body);
  position: relative;
  z-index: 2;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  padding: 0;
  width: 170px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 300ms ease-in-out;
  border-radius: 5px;
  overflow: hidden; }
  .btn:before {
    content: '';
    position: absolute;
    width: calc(100%);
    height: calc(100%);
    top: 0px;
    left: 0px;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: right center;
    transition: -webkit-transform 0.24s cubic-bezier(0.37, 0.31, 0.31, 0.9);
    transition: transform 0.24s cubic-bezier(0.37, 0.31, 0.31, 0.9);
    transition: transform 0.24s cubic-bezier(0.37, 0.31, 0.31, 0.9), -webkit-transform 0.24s cubic-bezier(0.37, 0.31, 0.31, 0.9);
    border-radius: inherit; }
  .btn:hover:before {
    transform: scaleX(1);
    transform-origin: left center; }
  .btn.active, .btn:active, .btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn.focus:active, .btn.active.focus {
    outline: none;
    box-shadow: none; }
  .btn .icon {
    margin-left: 10px; }

.btn-video {
  width: 73px;
  height: 73px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--global--color-white);
  border-radius: 50%;
  border: 1px solid var(--global--color-primary);
  transition: 0.3s ease-in-out; }
  .btn-video svg {
    fill: var(--global--color-primary);
    transition: 0.3s ease-in-out; }
  .btn-video:hover {
    background-color: var(--global--color-primary); }
    .btn-video:hover svg {
      fill: var(--global--color-white); }

a.back {
  position: absolute;
  top: 54px;
  left: 70px;
  font-size: 14px;
  font-weight: 500;
  line-height: 2.14;
  text-transform: capitalize;
  color: var(--global--color-white); }
  a.back:hover span {
    transform: rotate(-180deg) translateX(10px); }
  a.back span {
    transition: 0.3s ease-in-out;
    display: inline-block;
    transform: rotate(-180deg);
    margin-right: 15px; }

/* Button Primary */
.btn--primary {
  background-color: var(--global--color-primary);
  color: var(--global--color-white); }
  .btn--primary:before {
    background-color: var(--global--color-secondary); }
  .btn--primary:active, .btn--primary:focus, .btn--primary:hover {
    color: var(--global--color-white); }

.bg-dark .btn--primary:active, .bg-dark .btn--primary:focus, .bg-dark .btn--primary:hover {
  background-color: var(--color-hover);
  color: var(--global--color-white); }

/* Button Secondary*/
.btn--secondary {
  background-color: var(--global--color-secondary);
  color: var(--global--color-white); }
  .btn--secondary:active, .btn--secondary:focus, .btn--secondary:hover {
    background-color: var(--global--color-primary);
    color: var(--global--color-white); }
  .btn--secondary.btn--hover-white:active, .btn--secondary.btn--hover-white:focus, .btn--secondary.btn--hover-white:hover {
    background-color: var(--global--color-white);
    color: var(--color-hover); }

/* Button White */
.btn--white {
  background-color: var(--global--color-white);
  color: var(--global--color-heading);
  border: 1px solid var(--global--color-white);
  border-radius: 4px; }
  .btn--white:active, .btn--white:focus, .btn--white:hover {
    background-color: var(--global--color-primary);
    color: var(--global--color-white);
    border-color: var(--global--color-primary); }

/* Button Block */
.btn--block {
  width: 100%; }

.btn--auto {
  width: auto;
  padding: 0 17px; }

/* Button Rounded */
.btn--rounded {
  border-radius: 50px; }

/* Button Bordered */
.btn--bordered {
  background-color: transparent; }
  .btn--bordered.btn--primary {
    color: var(--global--color-primary);
    border-color: var(--global--color-primary); }
    .btn--bordered.btn--primary:active, .btn--bordered.btn--primary:focus, .btn--bordered.btn--primary:hover {
      color: var(--global--color-white);
      background-color: var(--global--color-primary);
      border-color: var(--global--color-primary); }
  .btn--bordered.btn--secondary {
    color: var(--global--color-heading);
    border-color: var(--global--color-heading); }
    .btn--bordered.btn--secondary:active, .btn--bordered.btn--secondary:focus, .btn--bordered.btn--secondary:hover {
      color: var(--global--color-white);
      background-color: var(--global--color-heading);
      border-color: var(--global--color-heading); }
  .btn--bordered.btn--white {
    color: var(--global--color-white);
    border-color: var(--global--color-white); }
    .btn--bordered.btn--white:active, .btn--bordered.btn--white:focus, .btn--bordered.btn--white:hover {
      color: var(--global--color-primary);
      background-color: var(--global--color-white);
      border-color: var(--global--color-white); }

.btn--white.btn--secondary {
  color: var(--global--color-secondary);
  border-color: var(--global--color-white);
  background-color: var(--global--color-white); }
  .btn--white.btn--secondary:active, .btn--white.btn--secondary:focus, .btn--white.btn--secondary:hover {
    color: var(--global--color-white);
    background-color: var(--global--color-primary);
    border-color: var(--global--color-primary); }

/*------------------------------------*\
    #FORMS
\*------------------------------------*/
.form-control {
  color: var(--global--color-body);
  font-family: var(--global--font-body);
  font-size: 16px;
  font-weight: 400;
  line-height: 57px;
  height: 57px;
  text-transform: none;
  border: 0;
  border-bottom: 1px solid #DEE2E6;
  background: transparent;
  padding: 0;
  margin-bottom: 20px;
  position: relative;
  border-radius: 0;
  box-shadow: none; }
  .form-control:focus {
    border-color: var(--global--color-primary);
    box-shadow: none;
    background: transparent; }
  .form-control::-webkit-input-placeholder {
    color: var(--global--color-body); }
  .form-control:-moz-placeholder {
    color: var(--global--color-body); }
  .form-control::-moz-placeholder {
    color: var(--global--color-body); }
  .form-control:-ms-input-placeholder {
    color: var(--global--color-body); }

/* Placeholder */
.input-group {
  border-radius: 0;
  border: 1px solid grey;
  background-color: transparent;
  overflow: hidden;
  padding: 3px; }
  .input-group .btn {
    width: 160px; }
  .input-group .input-group-btn {
    border-radius: 50px;
    overflow: hidden; }

/* Subscribe Alert */
.subscribe-alert {
  position: fixed;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  margin: 0;
  cursor: pointer;
  transform: translate3d(0, 200%, 0);
  animation: alertfadein .35s ease .5s both; }
  .subscribe-alert .alert {
    margin: 0 auto;
    font-size: 18px;
    height: 60px;
    line-height: 60px;
    border: none;
    border-radius: 0;
    padding: 0 30px;
    color: var(--global--color-white); }
    .subscribe-alert .alert:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      background-color: rgba(0, 0, 0, 0.1);
      font-weight: 600;
      font-size: 18px;
      cursor: pointer;
      transition: background-color .25s ease;
      content: "\f00d";
      font-family: "Font Awesome 5 Free"; }
    .subscribe-alert .alert.alert-danger {
      background-color: #cc0000; }
    .subscribe-alert .alert.alert-success {
      background-color: #198c19; }

@keyframes alertfadein {
  0% {
    transform: translate3d(0, 100%, 0); }
  100% {
    transform: none; } }

.form-inline .form-control {
  background-color: var(--global--color-white);
  font-size: 16px;
  font-weight: 400;
  width: 221px;
  height: 51px;
  border-radius: 50px;
  margin-right: 30px;
  padding-left: 20px;
  border-bottom: none;
  margin-bottom: 0; }

/*------------------------------------*\
    #Heading
\*------------------------------------*/
.heading {
  margin-bottom: 60px;
  position: relative;
  padding-bottom: 34px; }
  @media screen and (max-width: 991px) {
    .heading {
      text-align: center; } }
  .heading .heading-subtitle {
    font-family: var(--global--font-body);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 12px;
    color: var(--global--color-body);
    text-transform: capitalize; }
  .heading .heading-title {
    font-family: var(--global--font-body);
    color: var(--global--color-heading);
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 0px; }
  .heading .heading-desc {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.8;
    margin-bottom: 0;
    padding: 0; }
  .heading .divider {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90px;
    height: 3px;
    display: flex;
    align-items: center; }
    .heading .divider .line {
      background-color: var(--global--color-primary);
      width: 40px;
      height: 3px;
      margin: 0 10px; }
    .heading .divider::before {
      content: '';
      display: inline-flex;
      background-color: var(--global--color-primary);
      width: 15px;
      height: 3px; }
    .heading .divider::after {
      content: '';
      display: inline-flex;
      background-color: var(--global--color-primary);
      width: 15px;
      height: 3px; }
  .heading.heading-light .heading-subtitle {
    color: var(--global--color-subheading); }
  .heading.heading-light .heading-title {
    color: var(--global--color-white); }
  .heading.heading-2 {
    margin-bottom: 65px; }

/*------------------------------------*\
    #Clients
\*------------------------------------*/
.clients {
  padding-top: 290px;
  padding-bottom: 30px;
  overflow: visible; }
  .clients.bg-grey {
    background-color: #f5f5f5; }
  @media screen and (max-width: 991px) {
    .clients {
      padding-top: 30px;
      padding-bottom: 10px; } }
  .clients .client {
    padding-top: 60px;
    padding-bottom: 60px;
    cursor: pointer;
    transition: 0.3s ease;
    width: 150px;
    margin-right: auto;
    margin-left: auto;
    position: relative; }
    @media (min-width: 992px) and (max-width: 1200px) {
      .clients .client {
        width: 130px; } }
    .clients .client:hover::before {
      width: 100%; }
    .clients .client a {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      z-index: 2; }
    .clients .client img {
      max-width: 100%;
      width: auto;
      display: block;
      margin-right: auto;
      margin-left: auto; }

/*------------------------------------*\
    #Counters
\*------------------------------------*/
@media screen and (max-width: 991px) {
  .counters {
    padding-bottom: 35px; } }

@media screen and (max-width: 991px) {
  .counters .counter {
    text-align: center;
    margin-bottom: 30px; } }

.counters .counter .counter-content {
  display: flex;
  align-items: center; }
  @media screen and (max-width: 991px) {
    .counters .counter .counter-content {
      justify-content: center; } }
  .counters .counter .counter-content .counter-icon {
    width: 42px;
    margin-right: 15px; }
    .counters .counter .counter-content .counter-icon i {
      font-size: 40px;
      line-height: 40px;
      color: var(--global--color-subheading); }
    .counters .counter .counter-content .counter-icon img {
      max-width: 100%; }
  .counters .counter .counter-content .counter-num .counting {
    font-family: var(--global--font-body);
    font-weight: 500;
    font-size: 45px;
    color: var(--global--color-primary); }

.counters .counter .counter-name h6 {
  font-family: var(--global--font-body);
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  color: var(--global--color-subheading);
  text-transform: capitalize;
  margin-bottom: 0; }

/*------------------------------------*\
    #HEADER
\*------------------------------------*/
.header {
  height: 80px; }
  .header.header-transparent {
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1001; }

/*------------------------------------*\
    #Menu
\*------------------------------------*/
.header .navbar {
  margin-bottom: 0;
  border-radius: 0;
  min-height: 80px;
  border: none;
  padding: 0; }
  .header .navbar .navbar-brand {
    margin-right: 0; }
    .header .navbar .navbar-brand .logo-dark {
      display: none; }
    .header .navbar .navbar-brand .logo-light {
      display: inline-block; }
  .header .navbar .navbar-nav > li {
    margin-right: 30px;
    position: relative;
    transition: 0.3s ease-in-out; }
    .header .navbar .navbar-nav > li:last-child {
      margin-right: 0; }
    .header .navbar .navbar-nav > li > a {
      font-family: var(--global--font-body);
      font-size: 16px;
      text-transform: capitalize;
      font-weight: 400;
      line-height: 22px;
      color: var(--global--color-white);
      padding: 0;
      position: relative;
      display: block;
      transition: 0.3s ease-in-out; }
      @media screen and (min-width: 768px) {
        .header .navbar .navbar-nav > li > a::before {
          position: absolute;
          left: 0;
          bottom: -8px;
          content: '';
          height: 2px;
          width: 0;
          background-color: var(--global--color-white);
          transition: width 300ms ease-in-out; } }
    .header .navbar .navbar-nav > li.active > a,
    .header .navbar .navbar-nav > li > a:focus,
    .header .navbar .navbar-nav > li > a:hover {
      color: var(--global--color-white); }
      @media screen and (min-width: 992px) {
        .header .navbar .navbar-nav > li.active > a:before,
        .header .navbar .navbar-nav > li > a:focus:before,
        .header .navbar .navbar-nav > li > a:hover:before {
          background-color: var(--global--color-primary);
          width: 50%; } }
    @media screen and (max-width: 991px) {
      .header .navbar .navbar-nav > li {
        margin-right: 0;
        margin-bottom: 15px;
        border-radius: 4px; }
        .header .navbar .navbar-nav > li a {
          margin-right: 0;
          padding: 15px 10px;
          color: var(--global--color-heading);
          line-height: 1; }
        .header .navbar .navbar-nav > li.active, .header .navbar .navbar-nav > li:hover, .header .navbar .navbar-nav > li:focus, .header .navbar .navbar-nav > li:active {
          background-color: var(--global--color-primary); }
          .header .navbar .navbar-nav > li.active a, .header .navbar .navbar-nav > li:hover a, .header .navbar .navbar-nav > li:focus a, .header .navbar .navbar-nav > li:active a {
            color: var(--global--color-white); } }
  .header .navbar.navbar-fixed {
    top: 0;
    background: var(--global--color-white);
    box-shadow: 0 2px 4px rgba(3, 27, 78, 0.1);
    animation: navbarFixed 0.8s; }
    .header .navbar.navbar-fixed .navbar-brand .logo-dark {
      display: inline-block; }
    .header .navbar.navbar-fixed .navbar-brand .logo-light {
      display: none; }
    .header .navbar.navbar-fixed .navbar-nav > li > a {
      color: var(--global--color-heading); }
    @media screen and (max-width: 991px) {
      .header .navbar.navbar-fixed .navbar-nav > li.active a, .header .navbar.navbar-fixed .navbar-nav > li:hover a, .header .navbar.navbar-fixed .navbar-nav > li:focus a, .header .navbar.navbar-fixed .navbar-nav > li:active a {
        color: var(--global--color-white); } }

@keyframes navbarFixed {
  0% {
    opacity: 0;
    transform: translateY(-100%); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

/* Small Devices, Tablets */
@media only screen and (max-width: 576px) {
  .navbar .navbar-brand {
    max-width: 200px; }
    .navbar .navbar-brand img {
      max-width: 100%;
      height: auto; } }

@media only screen and (max-width: 991px) {
  .navbar .navbar-brand {
    margin-left: 15px;
    line-height: 80px; }
    .navbar .navbar-brand img {
      max-width: 100%;
      height: auto; }
  .navbar .navbar-toggler {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    outline: none;
    margin-right: 30px;
    padding: 0; } }
  @media only screen and (max-width: 991px) and (min-width: 992px) {
    .navbar .navbar-toggler {
      display: none; } }

@media only screen and (max-width: 991px) {
    .navbar .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
      background-color: transparent; }
      .navbar .navbar-toggler:not(.collapsed) .navbar-toggler-icon:before, .navbar .navbar-toggler:not(.collapsed) .navbar-toggler-icon:after {
        top: 0; }
      .navbar .navbar-toggler:not(.collapsed) .navbar-toggler-icon::before {
        transform: rotate(45deg); }
      .navbar .navbar-toggler:not(.collapsed) .navbar-toggler-icon::after {
        transform: rotate(-45deg); }
    .navbar .navbar-toggler .navbar-toggler-icon {
      width: 100%;
      height: 2px;
      background-color: var(--global--color-primary);
      position: relative;
      transition: 0.3s ease-in-out; }
      .navbar .navbar-toggler .navbar-toggler-icon:before, .navbar .navbar-toggler .navbar-toggler-icon:after {
        content: '';
        position: absolute;
        left: 0;
        height: 2px;
        width: 100%;
        background-color: var(--global--color-primary);
        transition: 0.3s ease-in-out; }
      .navbar .navbar-toggler .navbar-toggler-icon:before {
        top: -10px; }
      .navbar .navbar-toggler .navbar-toggler-icon:after {
        top: 10px; }
  .navbar .navbar-collapse {
    background-color: var(--global--color-white);
    padding: 15px; }
  .navbar.navbar-fixed .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
    background-color: transparent; }
  .navbar.navbar-fixed .navbar-toggler .navbar-toggler-icon {
    background-color: var(--global--color-primary); }
    .navbar.navbar-fixed .navbar-toggler .navbar-toggler-icon:before, .navbar.navbar-fixed .navbar-toggler .navbar-toggler-icon:after {
      background-color: var(--global--color-primary); } }

/* Large Devices, Wide Screens */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .header .navbar .navbar-nav > li {
    margin-right: 16px; }
  .module .module-icon,
  .module-consultation .btn {
    margin-right: 0; } }

@media only screen and (min-width: 992px) {
  .navbar-collapse {
    padding-right: 0;
    padding-left: 0; } }

/*------------------------------------*\
    #Modules
\*------------------------------------*/
@media screen and (min-width: 992px) {
  .header .module-container {
    margin-left: 30px;
    padding-left: 30px;
    position: relative; }
    .header .module-container::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 1px;
      height: 20px;
      background-color: #ffffff82; } }

@media screen and (max-width: 991px) {
  .header .module-container {
    padding-left: 10px;
    margin-top: 0px;
    margin-bottom: 10px; } }

.header .module-container .module-social a {
  margin-right: 35px; }
  .header .module-container .module-social a:last-child {
    margin-right: 0; }
  .header .module-container .module-social a i {
    color: var(--global--color-white);
    transition: 0.3s ease-in-out;
    display: inline-flex; }
    @media screen and (max-width: 991px) {
      .header .module-container .module-social a i {
        color: var(--global--color-heading); } }
    .header .module-container .module-social a i:hover {
      color: var(--global--color-primary);
      transform: translateY(-3px); }

@media screen and (min-width: 992px) {
  .header .navbar-fixed .module-container::before {
    background-color: #00000080; } }

.header .navbar-fixed .module-container .module-social a i {
  color: var(--global--color-heading); }
  .header .navbar-fixed .module-container .module-social a i:hover {
    color: var(--global--color-primary); }

/*------------------------------------*\
    #Hero
\*------------------------------------*/
.hero {
  overflow: visible;
  position: relative;
  padding: 0; }
  .hero .hero-cotainer {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 900px; }
    @media screen and (max-width: 991px) {
      .hero .hero-cotainer {
        height: auto;
        padding-top: 200px; } }
    .hero .hero-cotainer .hero-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-bottom: 170px; }
      @media screen and (min-width: 991px) {
        .hero .hero-cotainer .hero-content {
          padding-top: 190px; } }
      @media screen and (max-width: 991px) {
        .hero .hero-cotainer .hero-content {
          margin-bottom: 100px; } }
      .hero .hero-cotainer .hero-content .hero-subtitle {
        color: var(--global--color-white);
        font-family: var(--global--font-body);
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
        margin-bottom: 0; }
      .hero .hero-cotainer .hero-content .hero-title {
        color: var(--global--color-white);
        font-family: var(--global--font-body);
        font-size: 45px;
        font-weight: 500;
        line-height: 66px;
        margin-bottom: 27px; }
        @media screen and (max-width: 767px) {
          .hero .hero-cotainer .hero-content .hero-title {
            font-size: 34px;
            line-height: 1.2; } }
      .hero .hero-cotainer .hero-content .hero-action {
        display: flex; }
        .hero .hero-cotainer .hero-content .hero-action .btn {
          width: 200px;
          height: 60px; }
        .hero .hero-cotainer .hero-content .hero-action .contact-body .contactForm {
          position: relative; }
          .hero .hero-cotainer .hero-content .hero-action .contact-body .contactForm .form-control {
            margin-bottom: 0;
            background-color: var(--global--color-white);
            border-radius: 4px;
            padding-left: 40px;
            padding-right: 250px;
            border-bottom: 0; }
            @media screen and (max-width: 767px) {
              .hero .hero-cotainer .hero-content .hero-action .contact-body .contactForm .form-control {
                padding-left: 6px;
                padding-right: 200px; } }
          .hero .hero-cotainer .hero-content .hero-action .contact-body .contactForm .btn {
            position: absolute;
            top: 3px;
            right: 3px; }
            @media screen and (max-width: 767px) {
              .hero .hero-cotainer .hero-content .hero-action .contact-body .contactForm .btn {
                width: 170px; } }
        .hero .hero-cotainer .hero-content .hero-action .form-action {
          position: relative;
          width: 600px;
          margin-right: auto;
          margin-left: auto; }
          @media screen and (max-width: 576px) {
            .hero .hero-cotainer .hero-content .hero-action .form-action {
              width: 300px; } }
          @media screen and (min-width: 578px) and (max-width: 767px) {
            .hero .hero-cotainer .hero-content .hero-action .form-action {
              width: 500px; } }
          .hero .hero-cotainer .hero-content .hero-action .form-action .input-group {
            border: none;
            height: 65px;
            padding: 7px;
            border-radius: 6px;
            background-color: #f5f5f5; }
            .hero .hero-cotainer .hero-content .hero-action .form-action .input-group .btn {
              width: 170px;
              height: 50px;
              line-height: 50px;
              border-radius: 4px; }
              @media screen and (max-width: 767px) {
                .hero .hero-cotainer .hero-content .hero-action .form-action .input-group .btn {
                  width: 120px;
                  font-size: 13px; } }
          .hero .hero-cotainer .hero-content .hero-action .form-action .form-control {
            margin-bottom: 0;
            border-radius: 0;
            padding-left: 24px;
            padding-right: 200px;
            border-bottom: 0;
            width: calc(100% - 170px);
            height: 50px; }
            @media screen and (max-width: 767px) {
              .hero .hero-cotainer .hero-content .hero-action .form-action .form-control {
                padding-left: 15px;
                padding-right: 10px;
                font-size: 13px;
                width: calc(100% - 120px); } }
          .hero .hero-cotainer .hero-content .hero-action .form-action .btn {
            position: absolute;
            top: 7px;
            right: 7px;
            z-index: 60; }
    .hero .hero-cotainer .hero-image {
      overflow: visible; }
  .hero.hero-light .hero-cotainer .hero-content .hero-headline {
    color: var(--global--color-white); }
  .hero.hero-light .hero-cotainer .hero-content .hero-bio {
    color: #f8f9fa; }

/*------------------------------------*\
    #Testimonial
\*------------------------------------*/
.testimonials {
  padding-bottom: 70px;
  overflow: visible; }
  @media screen and (min-width: 992px) {
    .testimonials {
      padding-bottom: 0; } }
  .testimonials .testimonials-holder {
    border-radius: 4px;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15); }
    @media screen and (min-width: 992px) {
      .testimonials .testimonials-holder {
        transform: translateY(260px);
        margin-top: -260px; } }
    .testimonials .testimonials-holder .img-holder {
      background-size: auto;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      border-radius: 4px 0px 0px 4px;
      overflow: hidden; }
      @media screen and (max-width: 991px) {
        .testimonials .testimonials-holder .img-holder {
          height: 400px;
          border-radius: 4px 4px 0 0; } }
    .testimonials .testimonials-holder .testimonial-holder {
      border-radius: 0 4px 4px 0;
      background-size: auto; }
      @media screen and (max-width: 991px) {
        .testimonials .testimonials-holder .testimonial-holder {
          border-radius: 0 0 4px 4px; } }
      .testimonials .testimonials-holder .testimonial-holder .testimonial-panel {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 100px 100px 97px; }
        @media (min-width: 992px) and (max-width: 1200px) {
          .testimonials .testimonials-holder .testimonial-holder .testimonial-panel {
            padding-left: 60px;
            padding-right: 60px; } }
        @media screen and (max-width: 991px) {
          .testimonials .testimonials-holder .testimonial-holder .testimonial-panel {
            padding-top: 70px;
            padding-bottom: 70px; } }
        @media screen and (max-width: 767px) {
          .testimonials .testimonials-holder .testimonial-holder .testimonial-panel {
            padding-left: 20px;
            padding-right: 20px; } }
        .testimonials .testimonials-holder .testimonial-holder .testimonial-panel .testimonial-body {
          text-align: center; }
          .testimonials .testimonials-holder .testimonial-holder .testimonial-panel .testimonial-body .testimonial-name {
            font-family: var(--global--font-body);
            font-size: 22px;
            line-height: 33px;
            font-weight: 700;
            color: var(--global--color-heading);
            text-transform: capitalize;
            margin-bottom: 0; }
          .testimonials .testimonials-holder .testimonial-holder .testimonial-panel .testimonial-body .testimonial-title {
            font-family: var(--global--font-body);
            font-size: 16px;
            line-height: 1.5;
            font-weight: 500;
            text-transform: capitalize;
            color: var(--global--color-body);
            margin-bottom: 15px; }
          .testimonials .testimonials-holder .testimonial-holder .testimonial-panel .testimonial-body .rating i {
            color: var(--global--color-body);
            margin-right: 5px;
            font-size: 14px; }
            .testimonials .testimonials-holder .testimonial-holder .testimonial-panel .testimonial-body .rating i:last-child {
              margin-right: 0; }
            .testimonials .testimonials-holder .testimonial-holder .testimonial-panel .testimonial-body .rating i.active {
              color: #ffaa01; }
          .testimonials .testimonials-holder .testimonial-holder .testimonial-panel .testimonial-body .testimonial-desc {
            font-family: var(--global--font-body);
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            font-style: italic;
            color: var(--global--color-body);
            margin-bottom: 22px; }
        .testimonials .testimonials-holder .testimonial-holder .testimonial-panel .testimonial-img {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-bottom: 26px; }
          .testimonials .testimonials-holder .testimonial-holder .testimonial-panel .testimonial-img .author {
            width: 92px;
            height: 92px;
            box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
            border-radius: 50%; }
          .testimonials .testimonials-holder .testimonial-holder .testimonial-panel .testimonial-img .testimonial-quote {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 24px;
            padding: 0 20px; }
            .testimonials .testimonials-holder .testimonial-holder .testimonial-panel .testimonial-img .testimonial-quote img {
              width: auto; }

/*------------------------------------*\
    #Screenshots
\*------------------------------------*/
.screenshots {
  padding-bottom: 70px; }
  .screenshots .owl-carousel .owl-stage-outer {
    padding: 25px 0 45px; }
    .screenshots .owl-carousel .owl-stage-outer .owl-item {
      border-radius: 18px; }
      .screenshots .owl-carousel .owl-stage-outer .owl-item img {
        border-radius: 18px; }

@media screen and (max-width:991px) {
  .screenshots .owl-carousel .owl-stage-outer .owl-item img {
    text-align: center;
    max-width: 370px;
    margin-right: auto;
    margin-left: auto; } }
      .screenshots .owl-carousel .owl-stage-outer .owl-item.center {
        box-shadow: #dbdbdb78 0px 10px 40px; }

/*------------------------------------*\
    #Features
\*------------------------------------*/
.feature-panel {
  padding: 21px 30px 22px;
  border-radius: 4px;
  position: relative;
  box-shadow: none;
  display: flex;
  transition: 0.3s ease-in-out;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px dashed #9c9c9c; }
  @media screen and (max-width: 991px) {
    .feature-panel {
      max-width: 370px;
      margin: 0 auto 30px;
      padding: 15px; } }
  @media screen and (max-width: 767px) {
    .feature-panel {
      flex-direction: column;
      align-items: center; } }
  .feature-panel .feature-icon {
    flex-shrink: 0;
    width: 40px;
    margin-right: 20px;
    font-size: 40px;
    color: var(--global--color-subheading); }
    @media screen and (max-width: 767px) {
      .feature-panel .feature-icon {
        margin-right: 0;
        margin-bottom: 25px; } }
    .feature-panel .feature-icon i {
      font-size: 40px;
      line-height: 40px;
      color: var(--global--color-subheading); }
  .feature-panel .feature-content {
    flex-grow: 1; }
    @media screen and (max-width: 767px) {
      .feature-panel .feature-content {
        text-align: center; } }
    .feature-panel .feature-content h3 {
      font-family: var(--global--font-body);
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      margin-bottom: 11px;
      text-transform: capitalize;
      color: var(--global--color-white); }
      .feature-panel .feature-content h3 a {
        color: var(--global--color-white); }
        .feature-panel .feature-content h3 a:hover {
          color: var(--global--color-primary); }
    .feature-panel .feature-content p {
      font-family: var(--global--font-body);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: var(--global--color-white);
      margin-bottom: 0; }
  .feature-panel.feature-panel-1 {
    background-color: #E9F2F4; }
  .feature-panel.feature-panel-2 {
    background-color: #ECE6F2; }
  .feature-panel.feature-panel-3 {
    background-color: #EDE8E1; }
  .feature-panel.feature-panel-4 {
    background-color: #def4ef; }

/*------------------------------------*\
    #About
\*------------------------------------*/
@media screen and (max-width: 991px) {
  .about .heading {
    align-items: center; } }

@media screen and (max-width: 991px) {
  .about img {
    display: block;
    margin: 0 auto 30px auto; } }

/*------------------------------------*\
    #Processes
\*------------------------------------*/
.processes .process-panels-container {
  position: relative;
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 50px; }
  @media screen and (min-width: 992px) {
    .processes .process-panels-container {
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 40px; } }
  @media screen and (min-width: 1200px) {
    .processes .process-panels-container {
      grid-column-gap: 74px;
      grid-template-columns: 1fr 1fr 1fr; } }
  @media screen and (min-width: 992px) {
    .processes .process-panels-container .divider {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      z-index: 1;
      background-image: url("../images/cover/dot-line.png");
      background-size: contain;
      height: 50px;
      background-repeat: no-repeat;
      background-position-x: center; } }
  .processes .process-panels-container .process-panel {
    box-shadow: 0 10px 40px rgba(219, 219, 219, 0.45);
    border-radius: 10px;
    background-color: var(--global--color-white);
    padding: 45px 20px;
    transition: 0.4s ease-in-out;
    position: relative;
    z-index: 2;
    cursor: pointer; }
    .processes .process-panels-container .process-panel:hover {
      transform: translateY(-15px); }

@media screen and (max-width:991px) {
  .processes .process-panels-container .process-panel {
    text-align: center;
    max-width: 370px;
    margin-right: auto;
    margin-left: auto; } }
    .processes .process-panels-container .process-panel .process-phase {
      margin-bottom: 25px; }
      .processes .process-panels-container .process-panel .process-phase p {
        padding: 8px 15px;
        display: inline-block;
        border-radius: 18px;
        background-color: #E9F2F4;
        text-transform: capitalize;
        font-family: var(--global--font-heading);
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 0; }
    .processes .process-panels-container .process-panel .process-name {
      font-family: var(--global--font-heading);
      text-transform: capitalize;
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      margin-bottom: 20px; }
    .processes .process-panels-container .process-panel .process-desc {
      font-family: var(--global--font-body);
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      margin-bottom: 0;
      color: var(--global--color-body) 2; }
    .processes .process-panels-container .process-panel.process-panel-1 .process-phase p {
      background-color: #E9F2F4; }
    .processes .process-panels-container .process-panel.process-panel-2 .process-phase p {
      background-color: #EAE4EF; }
    .processes .process-panels-container .process-panel.process-panel-3 .process-phase p {
      background-color: #ECE7E0; }

.services .more-services p {
  font-family: var(--global--font-body);
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: var(--global--color-body);
  margin-bottom: 0; }
  .services .more-services p a {
    font-family: var(--global--font-body);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: var(--global--color-primary);
    text-transform: capitalize;
    transition: 0.3s ease-in-out;
    position: relative; }
    .services .more-services p a:hover {
      color: var(--global--color-heading); }
      .services .more-services p a:hover::before {
        background-color: var(--global--color-heading); }
    .services .more-services p a::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      transition: 0.3s ease-in-out;
      background-color: var(--global--color-primary); }
    .services .more-services p a i {
      font-size: 12px; }

.service-panel {
  border-radius: 4px;
  background-color: #f1f1f1;
  overflow: hidden; }
  @media screen and (min-width: 992px) {
    .service-panel {
      text-align: left;
      margin-bottom: 22px; } }
  @media screen and (max-width: 991px) {
    .service-panel {
      margin: 0 auto 30px;
      max-width: 370px; } }
  .service-panel .service-img {
    height: 300px; }
    .service-panel .service-img::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      content: '';
      z-index: 1;
      transition: transform 0.6s;
      transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0); }
    .service-panel .service-img:hover:before {
      transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0); }
  .service-panel .service-body {
    padding: 40px 40px 33px; }
    .service-panel .service-body .service-icon {
      margin-bottom: 22px; }
      .service-panel .service-body .service-icon i {
        font-size: 40px;
        line-height: 40px;
        color: var(--global--color-primary); }
    .service-panel .service-body .service-content {
      flex-grow: 1; }
      .service-panel .service-body .service-content h3 {
        font-family: var(--global--font-body);
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        margin-bottom: 11px;
        text-transform: capitalize;
        color: var(--global--color-heading); }
      .service-panel .service-body .service-content p {
        font-family: var(--global--font-body);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--global--color-body);
        margin-bottom: 0; }

/*------------------------------------*\
    #Call To Action
\*------------------------------------*/
.cta {
  position: relative;
  overflow: visible;
  padding: 85px 0; }
  .cta .container {
    padding-top: 60px;
    padding-bottom: 60px; }
    @media screen and (max-width: 991px) {
      .cta .container {
        padding-top: 65px;
        padding-bottom: 65px; } }

.form-mailchimp {
  position: relative;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 6px; }
  .form-mailchimp .input-group {
    display: flex;
    justify-content: space-between;
    border: none;
    height: 60px;
    padding: 0;
    border-radius: 4px;
    background-color: var(--global--color-white);
    position: relative;
    overflow: hidden; }
    @media screen and (max-width: 767px) {
      .form-mailchimp .input-group {
        flex-direction: column;
        height: auto; } }
    .form-mailchimp .input-group .btn {
      width: 200px;
      border-radius: 0 4px 4px 0;
      height: 60px;
      line-height: 60px;
      outline: none; }
      @media screen and (max-width: 767px) {
        .form-mailchimp .input-group .btn {
          border-radius: 4px;
          margin-left: auto;
          margin-right: auto; } }
    .form-mailchimp .input-group .input-icon {
      display: flex;
      align-items: center;
      flex-grow: 1; }
      .form-mailchimp .input-group .input-icon .form-control {
        margin-bottom: 0;
        border-bottom: 0;
        height: 60px;
        padding-left: 30px;
        padding-right: 20px;
        line-height: normal;
        font-size: 16px;
        font-weight: 500;
        font-family: var(--global--font-body);
        text-transform: capitalize;
        color: var(--global--color-body);
        background-color: #f5f5f5; }
        @media screen and (max-width: 767px) {
          .form-mailchimp .input-group .input-icon .form-control {
            margin-bottom: 30px; } }

.cta-note {
  text-align: center; }
  .cta-note p {
    margin-bottom: 0;
    font-family: var(--global--font-body);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--global--color-body); }
    .cta-note p i {
      margin-right: 5px; }

/*------------------------------------*\
    #Pricing
\*------------------------------------*/
.pricing {
  position: relative;
  padding-bottom: 100px; }
  @media screen and (max-width: 991px) {
    .pricing {
      padding-bottom: 40px; } }
  .pricing .pricing-panel {
    background-color: var(--global--color-white);
    padding: 40px 40px 50px;
    transition: all 0.3s ease-in-out;
    position: relative;
    background-color: #f1f1f1;
    border-radius: 4px; }
    @media screen and (max-width: 991px) {
      .pricing .pricing-panel {
        max-width: 370px;
        margin: 0 auto 30px; } }
    .pricing .pricing-panel .pricing-head {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      overflow: hidden;
      padding-bottom: 16px;
      border-bottom: 1px solid #dddddd; }
      .pricing .pricing-panel .pricing-head .pricing-type {
        margin-bottom: 10px;
        text-align: center; }
        .pricing .pricing-panel .pricing-head .pricing-type .per {
          font-family: var(--global--font-body);
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: var(--global--color-body);
          text-transform: lowercase;
          margin-bottom: 0; }
        .pricing .pricing-panel .pricing-head .pricing-type .price {
          font-family: var(--global--font-body);
          font-weight: 500;
          font-size: 50px;
          line-height: 48px;
          color: var(--global--color-heading);
          margin-bottom: 3px;
          position: relative; }
          .pricing .pricing-panel .pricing-head .pricing-type .price span {
            position: absolute;
            font-family: var(--global--font-body);
            font-weight: 400;
            font-size: 20px;
            color: var(--global--color-body);
            line-height: 1;
            top: -2px;
            left: -6px; }
      .pricing .pricing-panel .pricing-head .pricing-name {
        font-family: var(--global--font-body);
        font-size: 20px;
        font-weight: 500;
        line-height: 35px;
        text-transform: capitalize;
        color: var(--global--color-heading);
        margin-bottom: 19px; }
    .pricing .pricing-panel .pricing-body {
      text-align: center; }
      .pricing .pricing-panel .pricing-body .pricing-list {
        padding-top: 36px;
        margin-bottom: 25px; }
        .pricing .pricing-panel .pricing-body .pricing-list li {
          font-family: var(--global--font-body);
          font-size: 15px;
          line-height: 22px;
          color: var(--global--color-body);
          font-weight: 400;
          text-align: center;
          text-transform: capitalize;
          padding-bottom: 18px; }
          .pricing .pricing-panel .pricing-body .pricing-list li.available::before {
            content: '\f00c';
            font-weight: 700;
            font-family: 'Font Awesome 5 Free';
            margin-right: 14px;
            color: var(--global--color-primary); }
          .pricing .pricing-panel .pricing-body .pricing-list li.unavailable::before {
            content: '\f00d';
            font-weight: 700;
            font-family: 'Font Awesome 5 Free';
            margin-right: 14px;
            color: var(--global--color-body); }
      .pricing .pricing-panel .pricing-body .btn {
        width: 200px;
        height: 60px; }
        @media screen and (max-width: 991px) {
          .pricing .pricing-panel .pricing-body .btn {
            width: calc(100% - 30px);
            margin-right: auto;
            margin-left: auto; } }

/*------------------------------------*\
    #Footer
\*------------------------------------*/
.footer {
  padding-top: 100px;
  position: relative; }
  @media screen and (max-width: 991px) {
    .footer {
      padding-top: 70px; } }
  .footer .footer-top {
    text-align: center; }
    .footer .footer-top .footer-logo {
      margin-bottom: 55px; }
      .footer .footer-top .footer-logo img {
        max-width: 100%;
        height: auto; }
    .footer .footer-top .footer-links {
      margin-bottom: 87px; }
      @media screen and (max-width: 991px) {
        .footer .footer-top .footer-links {
          margin-bottom: 50px; } }
      .footer .footer-top .footer-links p,
      .footer .footer-top .footer-links a {
        font-family: var(--global--font-body);
        font-size: 16px;
        line-height: 24px;
        color: var(--global--color-white);
        display: inline-flex;
        align-items: center;
        margin-bottom: 10px; }
        @media screen and (max-width: 767px) {
          .footer .footer-top .footer-links p,
          .footer .footer-top .footer-links a {
            margin-bottom: 15px; } }
        .footer .footer-top .footer-links p i,
        .footer .footer-top .footer-links a i {
          margin-right: 9px; }
      .footer .footer-top .footer-links a:hover {
        color: var(--global--color-primary); }
  .footer hr {
    border-color: rgba(255, 255, 255, 0.2); }
  .footer .footer-bottom {
    min-height: 95px;
    display: flex;
    align-items: center; }
    @media screen and (max-width: 991px) {
      .footer .footer-bottom {
        padding-top: 30px;
        padding-bottom: 30px; } }
    @media screen and (max-width: 991px) {
      .footer .footer-bottom .footer-copyright {
        text-align: center;
        margin-bottom: 30px; } }
    .footer .footer-bottom .footer-copyright a,
    .footer .footer-bottom .footer-copyright span {
      font-family: var(--global--font-body);
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      text-transform: capitalize;
      color: var(--global--color-white); }
    .footer .footer-bottom .footer-copyright a:hover {
      color: var(--global--color-white); }
    .footer .footer-bottom .footer-social {
      text-align: right; }
      @media screen and (max-width: 991px) {
        .footer .footer-bottom .footer-social {
          text-align: center; } }
      .footer .footer-bottom .footer-social ul {
        margin-bottom: 0; }
        .footer .footer-bottom .footer-social ul li {
          display: inline-block;
          margin-right: 15px; }
          .footer .footer-bottom .footer-social ul li:last-child {
            margin-right: 0; }
          .footer .footer-bottom .footer-social ul li a {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.2);
            font-size: 14px;
            color: var(--global--color-white);
            transition: 0.3s ease-in-out; }
            .footer .footer-bottom .footer-social ul li a:hover {
              background-color: var(--global--color-primary);
              transform: scale(0.9); }

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .footer-copyright {
    font-size: 12px; } }

/*------------------------------------*\
    #Carousel
\*------------------------------------*/
.carousel-dots .owl-dots {
  display: flex;
  justify-content: center;
  margin-top: 40px; }
  .carousel-dots .owl-dots.disabled {
    display: flex; }
  .carousel-dots .owl-dots .owl-dot {
    width: 10px;
    height: 10px;
    border: solid 1px var(--global--color-primary);
    background-color: var(--global--color-primary);
    border-radius: 50%;
    margin-right: 13px; }
    .carousel-dots .owl-dots .owl-dot.active {
      background-color: var(--global--color-white);
      border: 2px solid var(--global--color-primary); }
    .carousel-dots .owl-dots .owl-dot:hover, .carousel-dots .owl-dots .owl-dot:focus {
      outline: none; }

.carousel-navs .owl-nav.disabled {
  display: flex; }

.carousel-navs .owl-nav [class*="owl-"] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  outline: none; }
  @media screen and (max-width: 1200px) {
    .carousel-navs .owl-nav [class*="owl-"] {
      display: none; } }
  .carousel-navs .owl-nav [class*="owl-"] span {
    display: none; }
  .carousel-navs .owl-nav [class*="owl-"]::before {
    font-family: 'icomoon';
    font-size: 30px;
    color: var(--global--color-primary); }
  .carousel-navs .owl-nav [class*="owl-"]:hover:before {
    color: var(--global--color-heading); }

.carousel-navs .owl-nav .owl-prev {
  left: -40px;
  transform: rotate(-180deg) translateY(50%); }
  @media screen and (max-width: 991px) {
    .carousel-navs .owl-nav .owl-prev {
      left: 0px; } }
  .carousel-navs .owl-nav .owl-prev::before {
    content: '\e901'; }

.carousel-navs .owl-nav .owl-next {
  right: -40px; }
  @media screen and (max-width: 991px) {
    .carousel-navs .owl-nav .owl-next {
      right: 0px; } }
  .carousel-navs .owl-nav .owl-next::before {
    content: '\e901'; }

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .carousel-dots .owl-controls {
    margin-top: 20px; } }

/*------------------------------------*\
    #Promo Page
\*------------------------------------*/
.landing-hero {
  padding: 200px 0 100px 0; }
  @media screen and (max-width: 991px) {
    .landing-hero {
      padding: 125px 0 90px 0; } }
  .landing-hero .landing-content {
    padding-bottom: 70px; }
    @media screen and (max-width: 991px) {
      .landing-hero .landing-content {
        padding-top: 50px;
        margin-bottom: 50px;
        text-align: center; } }
    .landing-hero .landing-content h1 {
      font-size: 45px;
      font-weight: 500;
      line-height: 1.5;
      color: var(--global--color-white);
      margin-bottom: 33px; }
      @media screen and (max-width: 991px) {
        .landing-hero .landing-content h1 {
          font-size: 34px;
          line-height: 50px; } }
      @media screen and (max-width: 1200px) {
        .landing-hero .landing-content h1 {
          font-size: 40px;
          line-height: 50px; } }
    @media screen and (max-width: 991px) {
      .landing-hero .landing-content {
        font-size: 50px;
        line-height: 55px; } }
    .landing-hero .landing-content p {
      font-family: var(--global--font-heading);
      font-size: 18px;
      line-height: 27px; }
    .landing-hero .landing-content .scroll-to i {
      transform: rotate(90deg);
      display: inline-block;
      color: var(--global--color-primary);
      font-size: 42px;
      cursor: pointer;
      animation: 1s Up-Down ease-in-out infinite; }

.landing-demos {
  padding: 120px 0 40px 0; }
  @media screen and (max-width: 991px) {
    .landing-demos {
      padding: 40px 0; } }
  .landing-demos .showcase-card {
    transition: all 300ms;
    margin: 0 auto 80px auto;
    font-size: 18px;
    font-weight: 700;
    color: var(--global--color-heading);
    text-align: center;
    text-transform: capitalize; }
    @media screen and (max-width: 991px) {
      .landing-demos .showcase-card {
        max-width: 500px; } }
    .landing-demos .showcase-card .showcase-card-img {
      box-shadow: 0px 30px 50px rgba(33, 37, 40, 0.1);
      margin-bottom: 30px; }
      .landing-demos .showcase-card .showcase-card-img img {
        max-width: 100%;
        height: auto;
        transition: all 300ms ease-in-out; }
    .landing-demos .showcase-card a {
      color: var(--global--color-heading);
      text-align: center;
      display: block;
      transition: 0.3s ease-in-out; }
      .landing-demos .showcase-card a:hover {
        color: var(--global--color-primary); }
    .landing-demos .showcase-card:hover {
      transform: translateY(-6px); }

.landing-action {
  padding: 220px 0 120px 0;
  overflow: visible;
  background-color: #262B47; }
  @media screen and (max-width: 991px) {
    .landing-action {
      margin-top: 0; } }
  .landing-action h3 {
    color: #fffefe;
    font-size: 45px;
    font-weight: 500;
    line-height: 60px;
    margin-bottom: 20px; }
    @media screen and (max-width: 991px) {
      .landing-action h3 {
        font-size: 28px;
        line-height: 35px; } }
  .landing-action p {
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 5px; }

.landing-featues {
  padding-bottom: 50px; }
  .landing-featues .feature-box {
    margin-bottom: 50px; }

@-webkit-keyframes Up-Down {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(20px); }
  100% {
    transform: translateY(0); } }

@-moz-keyframes Up-Down {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(20px); }
  100% {
    transform: translateY(0); } }

@keyframes Up-Down {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(20px); }
  100% {
    transform: translateY(0); } }
