/*------------------------------------*\
    #Features
\*------------------------------------*/
.feature-panel {
    padding         : 21px 30px 22px;
    border-radius   : 4px;
    position        : relative;
    box-shadow      : none;
    display         : flex;
    transition      : 0.3s ease-in-out;
    background-color: rgba(255, 255, 255, 0.2);
    border          : 1px dashed #9c9c9c;

    @media #{$maxTablet} {
        max-width: 370px;
        margin   : 0 auto 30px;
        padding  : 15px;
    }

    @media #{$maxSmall} {
        flex-direction: column;
        align-items   : center;
    }

    .feature-icon {
        flex-shrink : 0;
        width       : 40px;
        margin-right: 20px;
        font-size   : 40px;
        color       : var(--global--color-subheading);

        @media #{$maxSmall} {
            margin-right : 0;
            margin-bottom: 25px;
        }

        i {
            font-size  : 40px;
            line-height: 40px;
            color      : var(--global--color-subheading);
        }
    }

    .feature-content {
        flex-grow: 1;

        @media #{$maxSmall} {
            text-align: center;
        }

        h3 {
            font-family   : var(--global--font-body);
            font-size     : 20px;
            font-weight   : 500;
            line-height   : 30px;
            margin-bottom : 11px;
            text-transform: capitalize;
            color         : var(--global--color-white);

            a {
                color: var(--global--color-white);

                &:hover {
                    color: var(--global--color-primary);
                }
            }
        }

        p {
            font-family  : var(--global--font-body);
            font-size    : 16px;
            font-weight  : 400;
            line-height  : 24px;
            color        : var(--global--color-white);
            margin-bottom: 0;
        }
    }

    &.feature-panel-1 {
        background-color: #E9F2F4;
    }

    &.feature-panel-2 {
        background-color: #ECE6F2;
    }

    &.feature-panel-3 {
        background-color: #EDE8E1;
    }

    &.feature-panel-4 {
        background-color: #def4ef;
    }
}

/*------------------------------------*\
    #About
\*------------------------------------*/
.about {

    .heading {

        @media #{$maxTablet} {
            align-items: center;
        }
    }

    img {
        @media #{$maxTablet} {
            display: block;
            margin : 0 auto 30px auto;
        }
    }
}

/*------------------------------------*\
    #Processes
\*------------------------------------*/
.processes {
    .process-panels-container {
        position          : relative;
        display           : grid;
        grid-template-rows: auto;
        grid-row-gap      : 50px;
        ;

        @media #{$minLarge} {
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap      : 40px;
        }

        @media #{$minXlarge} {
            grid-column-gap      : 74px;
            grid-template-columns: 1fr 1fr 1fr;
        }

        .divider {
            @media #{$minLarge} {
                position             : absolute;
                top                  : 50%;
                transform            : translateY(-50%);
                left                 : 0;
                right                : 0;
                z-index              : 1;
                background-image     : url('../images/cover/dot-line.png');
                background-size      : contain;
                height               : 50px;
                background-repeat    : no-repeat;
                background-position-x: center;
            }
        }

        .process-panel {
            box-shadow      : 0 10px 40px rgba(219, 219, 219, .45);
            border-radius   : 10px;
            background-color: var(--global--color-white);
            padding         : 45px 20px;
            transition      : 0.4s ease-in-out;
            position        : relative;
            z-index         : 2;

            &:hover {
                transform: translateY(-15px);
            }

            cursor: pointer;

            @media#{$maxTablet} {
                text-align  : center;
                max-width   : 370px;
                margin-right: auto;
                margin-left : auto;
            }

            .process-phase {
                margin-bottom: 25px;

                p {
                    padding         : 8px 15px;
                    display         : inline-block;
                    border-radius   : 18px;
                    background-color: #E9F2F4;
                    text-transform  : capitalize;
                    font-family     : var(--global--font-heading);
                    font-size       : 15px;
                    font-weight     : 400;
                    margin-bottom   : 0;
                }
            }

            .process-name {
                font-family   : var(--global--font-heading);
                text-transform: capitalize;
                font-weight   : 600;
                font-size     : 22px;
                line-height   : 27px;
                margin-bottom : 20px;
            }

            .process-desc {
                font-family  : var(--global--font-body);
                font-size    : 18px;
                font-weight  : 400;
                line-height  : 28px;
                margin-bottom: 0;
                color        : var(--global--color-body)2;
            }

            &.process-panel-1 {
                .process-phase {
                    p {
                        background-color: #E9F2F4;
                    }
                }
            }

            &.process-panel-2 {
                .process-phase {
                    p {
                        background-color: #EAE4EF;
                    }
                }
            }

            &.process-panel-3 {
                .process-phase {
                    p {
                        background-color: #ECE7E0;
                    }
                }
            }
        }
    }
}