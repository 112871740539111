/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/
.btn {
    font-family    : var(--global--font-body);
    position       : relative;
    z-index        : 2;
    font-size      : 16px;
    font-weight    : 500;
    text-align     : center;
    padding        : 0;
    width          : 170px;
    height         : 50px;
    display        : inline-flex;
    align-items    : center;
    justify-content: center;
    transition     : 300ms ease-in-out;
    border-radius  : 5px;
    overflow       : hidden;

    &:before {
        content         : '';
        position        : absolute;
        width           : calc(100%);
        height          : calc(100%);
        top             : 0px;
        left            : 0px;
        z-index         : -1;
        transform       : scaleX(0);
        transform-origin: right center;
        transition      : -webkit-transform 0.24s cubic-bezier(0.37, 0.31, 0.31, 0.9);
        transition      : transform 0.24s cubic-bezier(0.37, 0.31, 0.31, 0.9);
        transition      : transform 0.24s cubic-bezier(0.37, 0.31, 0.31, 0.9), -webkit-transform 0.24s cubic-bezier(0.37, 0.31, 0.31, 0.9);
        border-radius   : inherit;
    }

    &:hover {
        &:before {
            transform       : scaleX(1);
            transform-origin: left center;
        }
    }

    &.active,
    &:active,
    &:focus,
    &:active:focus,
    &.active:focus,
    &.focus,
    &.focus:active,
    &.active.focus {
        outline   : none;
        box-shadow: none;
    }

    .icon {
        margin-left: 10px;
    }

}

.btn-video {
    width           : 73px;
    height          : 73px;
    display         : inline-flex;
    justify-content : center;
    align-items     : center;
    background-color: var(--global--color-white);
    border-radius   : 50%;
    border          : 1px solid var(--global--color-primary);
    transition      : 0.3s ease-in-out;

    svg {
        fill      : var(--global--color-primary);
        transition: 0.3s ease-in-out;
    }

    &:hover {
        background-color: var(--global--color-primary);

        svg {
            fill: var(--global--color-white)
        }
    }
}

a.back {
    position      : absolute;
    top           : 54px;
    left          : 70px;
    font-size     : 14px;
    font-weight   : 500;
    line-height   : 2.14;
    text-transform: capitalize;
    color         : var(--global--color-white);

    &:hover {
        span {
            transform: rotate(-180deg) translateX(10px);
        }
    }

    span {
        transition  : 0.3s ease-in-out;
        display     : inline-block;
        transform   : rotate(-180deg);
        margin-right: 15px;
    }
}

/* Button Primary */
.btn--primary {
    background-color: var(--global--color-primary);
    color           : var(--global--color-white);

    &:before {
        background-color: var(--global--color-secondary);
    }

    &:active,
    &:focus,
    &:hover {
        color: var(--global--color-white);
    }
}

.bg-dark {
    .btn--primary {

        &:active,
        &:focus,
        &:hover {
            background-color: var(--color-hover);
            color           : var(--global--color-white);
        }
    }
}

/* Button Secondary*/
.btn--secondary {
    background-color: var(--global--color-secondary);
    color           : var(--global--color-white);

    &:active,
    &:focus,
    &:hover {
        background-color: var(--global--color-primary);
        color           : var(--global--color-white);
    }

    &.btn--hover-white {

        &:active,
        &:focus,
        &:hover {
            background-color: var(--global--color-white);
            color           : var(--color-hover);
        }
    }
}

/* Button White */
.btn--white {
    background-color: var(--global--color-white);
    color           : var(--global--color-heading);
    border          : 1px solid var(--global--color-white);
    border-radius   : 4px;

    &:active,
    &:focus,
    &:hover {
        background-color: var(--global--color-primary);
        color           : var(--global--color-white);
        border-color    : var(--global--color-primary);
    }
}

/* Button Block */
.btn--block {
    width: 100%;
}

.btn--auto {
    width  : auto;
    padding: 0 17px;
}

/* Button Rounded */
.btn--rounded {
    border-radius: 50px;
}

/* Button Bordered */

.btn--bordered {
    background-color: transparent;

    &.btn--primary {
        color       : var(--global--color-primary);
        border-color: var(--global--color-primary);

        &:active,
        &:focus,
        &:hover {
            color           : var(--global--color-white);
            background-color: var(--global--color-primary);
            border-color    : var(--global--color-primary);
        }
    }

    &.btn--secondary {
        color       : var(--global--color-heading);
        border-color: var(--global--color-heading);

        &:active,
        &:focus,
        &:hover {
            color           : var(--global--color-white);
            background-color: var(--global--color-heading);
            border-color    : var(--global--color-heading);
        }
    }

    &.btn--white {
        color       : var(--global--color-white);
        border-color: var(--global--color-white);

        &:active,
        &:focus,
        &:hover {
            color           : var(--global--color-primary);
            background-color: var(--global--color-white);
            border-color    : var(--global--color-white);
        }
    }
}

.btn--white {
    &.btn--secondary {
        color           : var(--global--color-secondary);
        border-color    : var(--global--color-white);
        background-color: var(--global--color-white);

        &:active,
        &:focus,
        &:hover {
            color           : var(--global--color-white);
            background-color: var(--global--color-primary);
            border-color    : var(--global--color-primary);
        }
    }
}
