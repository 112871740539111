/*------------------------------------*\
    #Counters
\*------------------------------------*/
.counters {
    @media #{$maxTablet} {
        padding-bottom: 35px;
    }

    .counter {
        @media #{$maxTablet} {
            text-align   : center;
            margin-bottom: 30px;
        }

        .counter-content {
            display    : flex;
            align-items: center;

            @media #{$maxTablet} {
                justify-content: center;
            }

            .counter-icon {
                width       : 42px;
                margin-right: 15px;

                i{
                    font-size: 40px;
                    line-height: 40px;
                    color:var(--global--color-subheading);
                }

                img {
                    max-width: 100%;
                }
            }

            .counter-num {
                .counting {
                    font-family: var(--global--font-body);
                    font-weight: 500;
                    font-size  : 45px;
                    color      : var(--global--color-primary);
                }
            }
        }

        .counter-name {
            h6 {
                font-family   : var(--global--font-body);
                font-size     : 20px;
                line-height   : 30px;
                font-weight   : 700;
                color         : var(--global--color-subheading);
                text-transform: capitalize;
                margin-bottom : 0;
            }
        }
    }
}