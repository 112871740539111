/*------------------------------------*\
    #Pricing
\*------------------------------------*/
.pricing {
    position      : relative;
    padding-bottom: 100px;

    @media #{$maxTablet} {
        padding-bottom: 40px;
    }

    .pricing-panel {
        background-color: var(--global--color-white);
        padding         : 40px 40px 50px;
        transition      : all 0.3s ease-in-out;
        position        : relative;
        background-color: #f1f1f1;
        border-radius   : 4px;

        @media #{$maxTablet} {
            max-width: 370px;
            margin   : 0 auto 30px;
        }


        .pricing-head {
            position      : relative;
            display       : flex;
            flex-direction: column;
            align-items   : center;
            position      : relative;
            overflow      : hidden;
            padding-bottom: 16px;
            border-bottom : 1px solid #dddddd;

            .pricing-type {
                margin-bottom: 10px;
                text-align   : center;

                .per {
                    font-family   : var(--global--font-body);
                    font-size     : 16px;
                    font-weight   : 400;
                    line-height   : 24px;
                    color         : var(--global--color-body);
                    text-transform: lowercase;
                    margin-bottom : 0;
                }

                .price {
                    font-family  : var(--global--font-body);
                    font-weight  : 500;
                    font-size    : 50px;
                    line-height  : 48px;
                    color        : var(--global--color-heading);
                    margin-bottom: 3px;
                    position     : relative;

                    span {
                        position   : absolute;
                        font-family: var(--global--font-body);
                        font-weight: 400;
                        font-size  : 20px;
                        color      : var(--global--color-body);
                        line-height: 1;
                        top        : -2px;
                        left       : -6px;
                    }
                }
            }

            .pricing-name {
                font-family   : var(--global--font-body);
                font-size     : 20px;
                font-weight   : 500;
                line-height   : 35px;
                text-transform: capitalize;
                color         : var(--global--color-heading);
                margin-bottom : 19px;
            }
        }

        .pricing-body {
            text-align: center;

            .pricing-list {
                padding-top  : 36px;
                margin-bottom: 25px;

                li {
                    font-family   : var(--global--font-body);
                    font-size     : 15px;
                    line-height   : 22px;
                    color         : var(--global--color-body);
                    font-weight   : 400;
                    text-align    : center;
                    text-transform: capitalize;
                    padding-bottom: 18px;

                    &.available {
                        &::before {
                            content     : '\f00c';
                            font-weight : 700;
                            font-family : 'Font Awesome 5 Free';
                            margin-right: 14px;
                            color       : var(--global--color-primary);
                        }
                    }

                    &.unavailable {
                        &::before {
                            content     : '\f00d';
                            font-weight : 700;
                            font-family : 'Font Awesome 5 Free';
                            margin-right: 14px;
                            color       : var(--global--color-body);
                        }
                    }
                }
            }

            .btn {
                width : 200px;
                height: 60px;

                @media #{$maxTablet} {
                    width       : calc(100% - 30px);
                    margin-right: auto;
                    margin-left : auto;
                }
            }
        }
    }

}