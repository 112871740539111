/*------------------------------------*\
    #Clients
\*------------------------------------*/
.clients {
    padding-top   : 290px;
    padding-bottom: 30px;
    overflow      : visible;

    &.bg-grey {
        background-color: #f5f5f5;
    }

    @media #{$maxTablet} {
        padding-top   : 30px;
        padding-bottom: 10px;
    }

    .client {
        padding-top   : 60px;
        padding-bottom: 60px;
        cursor        : pointer;
        transition    : 0.3s ease;
        width         : 150px;
        margin-right  : auto;
        margin-left   : auto;
        position      : relative;

        @media #{$noteBook} {
            width: 130px;
        }

        &:hover {
            &::before {
                width: 100%;
            }
        }

        a {
            position: absolute;
            width   : 100%;
            height  : 100%;
            top     : 0;
            left    : 0;
            right   : 0;
            z-index : 2;
        }

        img {
            max-width   : 100%;
            width       : auto;
            display     : block;
            margin-right: auto;
            margin-left : auto;
        }
    }
}