.services {
    .more-services {
        p {
            font-family  : var(--global--font-body);
            font-weight  : 500;
            font-size    : 16px;
            line-height  : 30px;
            color        : var(--global--color-body);
            margin-bottom: 0;

            a {
                font-family   : var(--global--font-body);
                font-weight   : 500;
                font-size     : 16px;
                line-height   : 30px;
                color         : var(--global--color-primary);
                text-transform: capitalize;
                transition    : 0.3s ease-in-out;
                position      : relative;

                &:hover {
                    color: var(--global--color-heading);

                    &::before {
                        background-color: var(--global--color-heading);
                    }
                }

                &::before {
                    content         : '';
                    position        : absolute;
                    bottom          : 0;
                    left            : 0;
                    height          : 1px;
                    width           : 100%;
                    transition      : 0.3s ease-in-out;
                    background-color: var(--global--color-primary);
                }

                i {
                    font-size: 12px;
                }
            }
        }
    }
}

.service-panel {
    border-radius   : 4px;
    background-color: #f1f1f1;
    overflow        : hidden;

    @media #{$minLarge} {
        text-align   : left;
        margin-bottom: 22px;
    }

    @media #{$maxTablet} {
        margin   : 0 auto 30px;
        max-width: 370px;
    }

    .service-img {
        height: 300px;

        &::before {
            position  : absolute;
            top       : 0;
            left      : 0;
            width     : 100%;
            height    : 100%;
            background: rgba(255, 255, 255, 0.5);
            content   : '';
            z-index   : 1;
            transition: transform 0.6s;
            transform : scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
        }

        &:hover {

            &:before {
                transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
            }

        }
    }

    .service-body {
        padding: 40px 40px 33px;

        .service-icon {
            margin-bottom: 22px;
            i{
                font-size    : 40px;
                line-height: 40px;
            color        : var(--global--color-primary);
            }
        }

        .service-content {
            flex-grow: 1;

            h3 {
                font-family   : var(--global--font-body);
                font-size     : 20px;
                font-weight   : 500;
                line-height   : 30px;
                margin-bottom : 11px;
                text-transform: capitalize;
                color         : var(--global--color-heading);
            }

            p {
                font-family  : var(--global--font-body);
                font-size    : 16px;
                font-weight  : 400;
                line-height  : 24px;
                color        : var(--global--color-body);
                margin-bottom: 0;
            }
        }
    }



}