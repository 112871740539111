/*------------------------------------*\
    #FORMS
\*------------------------------------*/
.form-control {
    color         : var(--global--color-body);
    font-family   : var(--global--font-body);
    font-size     : 16px;
    font-weight   : 400;
    line-height   : 57px;
    height        : 57px;
    text-transform: none;
    border        : 0;
    border-bottom : 1px solid #DEE2E6;
    background    : transparent;
    padding       : 0;
    margin-bottom : 20px;
    position      : relative;
    border-radius : 0;
    box-shadow    : none;

    &:focus {
        border-color: var(--global--color-primary);
        box-shadow  : none;
        background  : transparent;
    }

    &::-webkit-input-placeholder {
        color: var(--global--color-body);
    }

    &:-moz-placeholder {
        color: var(--global--color-body);
    }

    &::-moz-placeholder {
        color: var(--global--color-body);
    }

    &:-ms-input-placeholder {
        color: var(--global--color-body);
    }
}

/* Placeholder */

.input-group {
    border-radius   : 0;
    border          : 1px solid grey;
    background-color: transparent;
    overflow        : hidden;
    padding         : 3px;

    .btn {
        width: 160px;
    }

    .input-group-btn {
        border-radius: 50px;
        overflow     : hidden;
    }
}

/* Subscribe Alert */
.subscribe-alert {
    position : fixed;
    top      : auto;
    right    : 0;
    bottom   : 0;
    left     : 0;
    z-index  : 99999;
    margin   : 0;
    cursor   : pointer;
    transform: translate3d(0, 200%, 0);
    animation: alertfadein .35s ease .5s both;

    .alert {
        margin       : 0 auto;
        font-size    : 18px;
        height       : 60px;
        line-height  : 60px;
        border       : none;
        border-radius: 0;
        padding      : 0 30px;
        color        : var(--global--color-white);

        &:after {
            position        : absolute;
            top             : 0;
            right           : 0;
            bottom          : 0;
            display         : flex;
            align-items     : center;
            justify-content : center;
            width           : 60px;
            background-color: rgba(0, 0, 0, .1);
            font-weight     : 600;
            font-size       : 18px;
            cursor          : pointer;
            transition      : background-color .25s ease;
            content         : "\f00d";
            font-family     : $fontawesome;
        }

        &.alert-danger {
            background-color: #cc0000;
        }

        &.alert-success {
            background-color: #198c19;
        }
    }
}

@keyframes alertfadein {
    0% {
        transform: translate3d(0, 100%, 0);
    }

    100% {
        transform: none;
    }
}

.form-inline {
    .form-control {
        background-color: var(--global--color-white);
        font-size       : 16px;
        font-weight     : 400;
        width           : 221px;
        height          : 51px;
        border-radius   : 50px;
        margin-right    : 30px;
        padding-left    : 20px;
        border-bottom   : none;
        margin-bottom   : 0;
    }
}