/*------------------------------------*\
    #Menu
\*------------------------------------*/
.header {
	.navbar {
		margin-bottom: 0;
		border-radius: 0;
		min-height   : 80px;
		border       : none;
		padding      : 0;

		.navbar-brand {
			margin-right: 0;

			.logo-dark {
				display: none;
			}

			.logo-light {
				display: inline-block;
			}
		}

		.navbar-nav {
			>li {
				margin-right: 30px;
				position    : relative;
				transition  : 0.3s ease-in-out;

				&:last-child {
					margin-right: 0;
				}

				>a {
					font-family   : var(--global--font-body);
					font-size     : 16px;
					text-transform: capitalize;
					font-weight   : 400;
					line-height   : 22px;
					color         : var(--global--color-white);
					padding       : 0;
					position      : relative;
					display       : block;
					transition    : 0.3s ease-in-out;

					@media #{$minTablet} {
						&::before {
							position        : absolute;
							left            : 0;
							bottom          : -8px;
							content         : '';
							height          : 2px;
							width           : 0;
							background-color: var(--global--color-white);
							transition      : width 300ms ease-in-out;
						}
					}
				}

				&.active>a,
				>a:focus,
				>a:hover {
					color: var(--global--color-white);

					@media #{$minLarge} {
						&:before {
							background-color: var(--global--color-primary);
							width           : 50%;
						}
					}
				}

				@media #{$maxTablet} {
					margin-right : 0;
					margin-bottom: 15px;
					border-radius: 4px;

					a {
						margin-right: 0;
						padding     : 15px 10px;
						color       : var(--global--color-heading);
						line-height : 1;
					}

					&.active,
					&:hover,
					&:focus,
					&:active {
						background-color: var(--global--color-primary);

						a {
							color: var(--global--color-white)
						}
					}
				}
			}
		}

		&.navbar-fixed {
			top       : 0;
			background: var(--global--color-white);
			box-shadow: 0 2px 4px rgba(3, 27, 78, 0.1);
			animation : navbarFixed 0.8s;

			.navbar-brand {
				.logo-dark {
					display: inline-block;
				}

				.logo-light {
					display: none;
				}
			}

			.navbar-nav {
				>li {
					>a {
						color: var(--global--color-heading);
					}

					&.active,
					&:hover,
					&:focus,
					&:active {
						@media #{$maxTablet} {
							a {
								color: var(--global--color-white)
							}
						}
					}
				}
			}
		}
	}
}

@keyframes navbarFixed {
	0% {
		opacity  : 0;
		transform: translateY(-100%);
	}

	100% {
		opacity  : 1;
		transform: translateY(0);
	}
}