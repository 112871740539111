/* Small Devices, Tablets */
@media only screen and (max-width:576px) {
	.navbar {
		.navbar-brand {
			max-width: 200px;

			img {
				max-width: 100%;
				height   : auto;
			}
		}
	}
}

@media only screen and (max-width: 991px) {
	.navbar {
		.navbar-brand {
			margin-left: 15px;
			line-height: 80px;

			img {
				max-width: 100%;
				height   : auto;
			}
		}

		.navbar-toggler {
			width          : 30px;
			height         : 30px;
			display        : flex;
			justify-content: center;
			align-items    : center;
			transition     : 0.3s ease-in-out;
			cursor         : pointer;
			outline        : none;
			margin-right   : 30px;
			padding        : 0;

			@media screen and (min-width:992px) {
				display: none;
			}

			&:not(.collapsed) {

				.navbar-toggler-icon {
					background-color: transparent;

					&:before,
					&:after {
						top: 0;
					}

					&::before {
						transform: rotate(45deg);
					}

					&::after {
						transform: rotate(-45deg);
					}
				}

			}

			.navbar-toggler-icon {
				width           : 100%;
				height          : 2px;
				background-color: var(--global--color-primary);
				position        : relative;
				transition      : 0.3s ease-in-out;

				&:before,
				&:after {
					content         : '';
					position        : absolute;
					left            : 0;
					height          : 2px;
					width           : 100%;
					background-color: var(--global--color-primary);
					transition      : 0.3s ease-in-out;
				}

				&:before {
					top: -10px;
				}

				&:after {
					top: 10px;
				}
			}
		}

		.navbar-collapse {
			background-color: var(--global--color-white);
			padding         : 15px;
		}

		&.navbar-fixed {
			.navbar-toggler {
				&:not(.collapsed) {
					.navbar-toggler-icon {
						background-color: transparent;
					}
				}

				.navbar-toggler-icon {
					background-color: var(--global--color-primary);

					&:before,
					&:after {
						background-color: var(--global--color-primary);
					}
				}
			}
		}
	}
}