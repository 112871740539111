/*------------------------------------*\
    #Heading
\*------------------------------------*/

.heading {
    margin-bottom : 60px;
    position      : relative;
    padding-bottom: 34px;

    @media #{$maxTablet} {
        text-align: center;
    }

    .heading-subtitle {
        font-family   : var(--global--font-body);
        font-size     : 16px;
        font-weight   : 400;
        line-height   : 24px;
        margin-bottom : 12px;
        color         : var(--global--color-body);
        text-transform: capitalize;
    }

    .heading-title {
        font-family  : var(--global--font-body);
        color        : var(--global--color-heading);
        font-size    : 32px;
        font-weight  : 500;
        margin-bottom: 0px;
    }

    .heading-desc {
        font-size    : 16px;
        font-weight  : 400;
        line-height  : 1.8;
        margin-bottom: 0;
        padding      : 0;
    }

    .divider {
        position   : absolute;
        bottom     : 0;
        left       : 50%;
        transform  : translateX(-50%);
        width      : 90px;
        height     : 3px;
        display    : flex;
        align-items: center;

        .line {
            background-color: var(--global--color-primary);
            width           : 40px;
            height          : 3px;
            margin          : 0 10px;
        }

        &::before {
            content         : '';
            display         : inline-flex;
            background-color: var(--global--color-primary);
            width           : 15px;
            height          : 3px;
        }

        &::after {
            content         : '';
            display         : inline-flex;
            background-color: var(--global--color-primary);
            width           : 15px;
            height          : 3px;
        }
    }

    &.heading-light {
        .heading-subtitle {
            color: var(--global--color-subheading);
        }

        .heading-title {
            color: var(--global--color-white);
        }
    }

    &.heading-2 {
        margin-bottom: 65px;
    }
}