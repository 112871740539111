/*------------------------------------*\
    #Testimonial
\*------------------------------------*/
.testimonials {
    padding-bottom: 70px;
    overflow      : visible;

    @media #{$minLarge} {
        padding-bottom: 0;
    }

    .testimonials-holder {
        border-radius: 4px;
        box-shadow   : 0px 10px 30px 0px rgba(0, 0, 0, 0.15);

        @media #{$minLarge} {
            transform : translateY(260px);
            margin-top: -260px;
        }

        .img-holder {
            background-size: auto;
            display        : flex;
            flex-direction : column;
            flex-grow      : 1;
            border-radius  : 4px 0px 0px 4px;
            overflow       : hidden;

            @media #{$maxTablet} {
                height       : 400px;
                border-radius: 4px 4px 0 0;
            }
        }

        .testimonial-holder {
            border-radius  : 0 4px 4px 0;
            background-size: auto;

            @media #{$maxTablet} {
                border-radius: 0 0 4px 4px;
            }

            .testimonial-panel {
                overflow      : hidden;
                display       : flex;
                flex-direction: column;
                align-items   : center;
                padding       : 100px 100px 97px;

                @media #{$noteBook} {
                    padding-left : 60px;
                    padding-right: 60px;
                }

                @media #{$maxTablet} {
                    padding-top   : 70px;
                    padding-bottom: 70px;
                }

                @media #{$maxSmall} {
                    padding-left : 20px;
                    padding-right: 20px;
                }

                .testimonial-body {
                    text-align: center;

                    .testimonial-name {
                        font-family   : var(--global--font-body);
                        font-size     : 22px;
                        line-height   : 33px;
                        font-weight   : 700;
                        color         : var(--global--color-heading);
                        text-transform: capitalize;
                        margin-bottom : 0;
                    }

                    .testimonial-title {
                        font-family   : var(--global--font-body);
                        font-size     : 16px;
                        line-height   : 1.5;
                        font-weight   : 500;
                        text-transform: capitalize;
                        color         : var(--global--color-body);
                        margin-bottom : 15px;
                    }

                    .rating {
                        i {
                            color       : var(--global--color-body);
                            margin-right: 5px;
                            font-size   : 14px;

                            &:last-child {
                                margin-right: 0;
                            }

                            &.active {
                                color: #ffaa01;
                            }
                        }
                    }

                    .testimonial-desc {
                        font-family  : var(--global--font-body);
                        font-size    : 16px;
                        font-weight  : 400;
                        line-height  : 24px;
                        font-style   : italic;
                        color        : var(--global--color-body);
                        margin-bottom: 22px;
                    }
                }

                .testimonial-img {
                    position       : relative;
                    display        : flex;
                    align-items    : center;
                    justify-content: center;
                    width          : 100%;
                    margin-bottom  : 26px;

                    .author {
                        width        : 92px;
                        height       : 92px;
                        box-shadow   : 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
                        border-radius: 50%;
                    }

                    .testimonial-quote {
                        position       : absolute;
                        top            : 50%;
                        transform      : translateY(-50%);
                        display        : flex;
                        align-items    : center;
                        justify-content: space-between;
                        width          : 100%;
                        height         : 24px;
                        padding        : 0 20px;

                        img {
                            width: auto;
                        }
                    }
                }
            }
        }

    }
}

/*------------------------------------*\
    #Screenshots
\*------------------------------------*/

.screenshots {
    padding-bottom: 70px;

    .owl-carousel {
        .owl-stage-outer {
            padding: 25px 0 45px;

            .owl-item {
                border-radius: 18px;

                img {
                    border-radius: 18px;

                    @media#{$maxTablet} {
                        text-align  : center;
                        max-width   : 370px;
                        margin-right: auto;
                        margin-left : auto;
                    }
                }

                &.center {
                    box-shadow: #dbdbdb78 0px 10px 40px;
                }
            }
        }
    }
}