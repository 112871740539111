/*------------------------------------*\
    #HEADER
\*------------------------------------*/
.header {
    height: 80px;

    &.header-transparent {
        background-color: transparent;
        position        : absolute;
        top             : 0;
        left            : 0;
        width           : 100%;
        z-index         : 1001;
    }
}