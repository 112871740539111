/*------------------------------------*\
    #BACKGROUNDS
\*------------------------------------*/
.bg-theme {
	background-color: var(--global--color-primary) !important
}

.bg-primary-alt {
	background-color: var(--color-hover) !important
}

.bg-gray {
	background-color: --global--color-gray !important;
}

.bg-white {
	background-color: var(--global--color-white) !important
}

.bg-pink {
	background-color: var(--global--color-pink) !important;
	border-top      : 1px solid #f8e2ff;
	border-bottom   : 1px solid #f8e2ff;

}

.bg-dark {
	background-color: var(--global--color-heading) !important
}

.bg-gradient {
	background: bottom center var(--global--color-white);
}

.bg-light-primary {
	background-color: #F1F8FE !important;
}

/* Background Image */
.bg-section {
	position           : relative;
	overflow           : hidden;
	z-index            : 1;
	background-size    : cover;
	background-repeat  : no-repeat;
	background-position: center center;
	width              : 100%;
	height             : 100%;
}

.bg-content {
	background-size: contain;
}

.bg-overlay:before {
	content : "";
	display : inline-block;
	height  : 100%;
	left    : 0;
	position: absolute;
	top     : 0;
	width   : 100%;
	z-index : -1;
}

.bg-overlay-light:before {
	background-color: var(--global--color-white);
	opacity         : 0.8;
}

.bg-overlay-dark:before {
	background-color: rgb(0, 0, 0);
	opacity         : 0.502;
}

.bg-overlay-dark-2:before {
	background-color: rgb(0, 0, 0);
	opacity         : 0.6;
}

.bg-overlay-grey:before {
	background-color: rgb(245, 245, 245);
	opacity         : 0.902;
}

.bg-overlay-theme:before {
	background-color: var(--global--color-primary);
	opacity         : 0.7;
}

.bg-parallax {
	background-attachment: fixed;
}

.bg-ytvideo.bg-overlay:before {
	z-index: 1;
	opacity: .7;
}

/* Background Color Palettes */

.bg-purple {
	color           : var(--global--color-white);
	background-color: #cc66cc !important;
}

.bg-sun-flower {
	color           : var(--global--color-white);
	background-color: #f1c40f !important;
}

.bg-orange {
	color           : var(--global--color-white);
	background-color: #f39c12 !important;
}

.bg-carrot {
	color           : var(--global--color-white);
	background-color: #e67e22 !important;
}

.bg-pumpkin {
	color           : var(--global--color-white);
	background-color: #d35400 !important;
}

.bg-alizarin {
	color           : var(--global--color-white);
	background-color: #e74c3c !important;
}

.bg-pomegranate {
	color           : var(--global--color-white);
	background-color: #c0392b !important;
}

.bg-turquoise {
	color           : var(--global--color-white);
	background-color: #1abc9c !important;
}

.bg-green-sea {
	color           : var(--global--color-white);
	background-color: #16a085 !important;
}

.bg-emerald {
	color           : var(--global--color-white);
	background-color: #2ecc71 !important;
}

.bg-nephritis {
	color           : var(--global--color-white);
	background-color: #27ae60 !important;
}

.bg-peter-river {
	color           : var(--global--color-white);
	background-color: #3498db !important;
}

.bg-belize-hole {
	color           : var(--global--color-white);
	background-color: #2980b9 !important;
}

.bg-amethyst {
	color           : var(--global--color-white);
	background-color: #9b59b6 !important;
}

.bg-wisteria {
	color           : var(--global--color-white);
	background-color: #8e44ad !important;
}

.bg-wet-asphalt {
	color           : var(--global--color-white);
	background-color: #34495e !important;
}

.bg-wet-asphalt {
	color           : var(--global--color-white);
	background-color: #34495e !important;
}

.bg-midnight-blue {
	color           : var(--global--color-white);
	background-color: #2c3e50 !important;
}



.bg-silver {
	color           : var(--global--color-white);
	background-color: #bdc3c7 !important;
}

.bg-concrete {
	color           : var(--global--color-white);
	background-color: #859596 !important;
}

.bg-asbestos {
	color           : var(--global--color-white);
	background-color: #7f8c8d !important;
}

.bg-asbestos {
	color           : var(--global--color-white);
	background-color: #7f8c8d !important;
}

.bg-graphite {
	color           : var(--global--color-white);
	background-color: #454545 !important;
}

.bg-gray-0 {
	background-color: #EEEEEE !important;
	color           : #454545;
}

.bg-gray-1 {
	background-color: #ECECEC !important;
	color           : #454545;
}

.bg-gray-2 {
	background-color: #BDC3C7 !important;
	color           : #454545;
}

.bg-gray-3 {
	background-color: #DADFE1 !important;
	color           : #454545;
}

.bg-gray-5 {
	background-color: #ECF0F1 !important;
	color           : #454545;
}

.bg-gray-4 {
	background-color: #F2F1EF !important;
	color           : #454545;
}

.bg-gray-6 {
	background-color: #D2D7D3 !important;
	color           : #454545;
}

.bg-gray-7 {
	background-color: #E6E6E6 !important;
	color           : #454545;
}

.bg-orange-1 {
	background-color: #F9690E !important;
	color           : var(--global--color-white);
}

.bg-orange-2 {
	background-color: #D35400 !important;
	color           : var(--global--color-white);
}

.bg-orange-3 {
	background-color: #F89406 !important;
	color           : var(--global--color-white);
}

.divider-1 {
	height          : 1px;
	background-color: #fce5e6;
}

.divider-2 {
	height          : 1px;
	width           : 100%;
	background-color: #f8e2ff;
}

.skew-divider {
	width     : 100%;
	height    : 100px;
	position  : absolute;
	left      : 0px;
	background: linear-gradient(to right bottom, #FEFBFF 49%, #fff 50%), linear-gradient(-50deg, #ffffff 16px, #000 0);

	&.divider-theme {
		background: linear-gradient(to right bottom, var(--global--color-primary) 49%, #fff 50%), linear-gradient(-50deg, #ffffff 16px, #000 0);
	}
}

.skew-divider-top {
	width     : 100%;
	height    : 100px;
	position  : absolute;
	left      : 0px;
	top       : 0;
	background: linear-gradient(to right bottom, #ffffff 49%, #262B47 50%), linear-gradient(-50deg, #ffffff 16px, #000 0);
}