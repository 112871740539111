/*------------------------------------*\
    #Footer
\*------------------------------------*/
.footer {
    padding-top: 100px;
    position   : relative;

    @media #{$maxTablet} {
        padding-top: 70px;
    }

    .footer-top {
        text-align: center;

        .footer-logo {
            margin-bottom: 55px;

            img {
                max-width: 100%;
                height   : auto;
            }
        }

        .footer-links {
            margin-bottom: 87px;

            @media #{$maxTablet} {
                margin-bottom: 50px;
            }

            p,
            a {
                font-family  : var(--global--font-body);
                font-size    : 16px;
                line-height  : 24px;
                color        : var(--global--color-white);
                display      : inline-flex;
                align-items  : center;
                margin-bottom: 10px;

                @media #{$maxSmall} {
                    margin-bottom: 15px;
                }

                i {
                    margin-right: 9px;
                }
            }

            a {
                &:hover {
                    color: var(--global--color-primary);
                }
            }
        }

    }

    hr {
        border-color: rgba(255, 255, 255, .2);
    }

    .footer-bottom {
        min-height : 95px;
        display    : flex;
        align-items: center;

        @media #{$maxTablet} {
            padding-top   : 30px;
            padding-bottom: 30px;
        }

        .footer-copyright {
            @media #{$maxTablet} {
                text-align   : center;
                margin-bottom: 30px;
            }

            a,
            span {
                font-family   : var(--global--font-body);
                font-size     : 16px;
                font-weight   : 400;
                line-height   : 26px;
                text-transform: capitalize;
                color         : var(--global--color-white);
            }

            a {
                &:hover {
                    color: var(--global--color-white);
                }
            }
        }

        .footer-social {
            text-align: right;

            @media #{$maxTablet} {
                text-align: center;
            }

            ul {
                margin-bottom: 0;

                li {
                    display     : inline-block;
                    margin-right: 15px;

                    &:last-child {
                        margin-right: 0;
                    }

                    a {
                        display         : inline-flex;
                        justify-content : center;
                        align-items     : center;
                        width           : 35px;
                        height          : 35px;
                        border-radius   : 50%;
                        background-color: rgba(#fff, 0.2);
                        font-size       : 14px;
                        color           : var(--global--color-white);
                        transition      : 0.3s ease-in-out;

                        &:hover {
                            background-color: var(--global--color-primary);
                            transform       : scale(0.9);
                        }
                    }
                }
            }

        }

    }

}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .footer-copyright {
        font-size: 12px;
    }
}