/*------------------------------------*\
    #Hero
\*------------------------------------*/

.hero {
    overflow: visible;
    position: relative;
    padding : 0;

    .hero-cotainer {
        position   : relative;
        display    : flex;
        align-items: center;
        min-height : 900px;

        @media #{$maxTablet} {
            height     : auto;
            padding-top: 200px;
        }

        .hero-content {
            display       : flex;
            flex-direction: column;
            align-items   : center;
            text-align    : center;
            margin-bottom : 170px;

            @media screen and (min-width:991px) {
                padding-top: 190px;
            }


            @media #{$maxTablet} {
                margin-bottom: 100px;
            }

            .hero-subtitle {
                color        : var(--global--color-white);
                font-family  : var(--global--font-body);
                font-size    : 18px;
                font-weight  : 700;
                line-height  : 27px;
                margin-bottom: 0;
            }

            .hero-title {
                color        : var(--global--color-white);
                font-family  : var(--global--font-body);
                font-size    : 45px;
                font-weight  : 500;
                line-height  : 66px;
                margin-bottom: 27px;

                @media #{$maxSmall} {
                    font-size  : 34px;
                    line-height: 1.2;
                }
            }


            .hero-action {
                display: flex;

                .btn {
                    width : 200px;
                    height: 60px;
                }

                .contact-body {
                    .contactForm {
                        position: relative;

                        .form-control {
                            margin-bottom   : 0;
                            background-color: var(--global--color-white);
                            border-radius   : 4px;
                            padding-left    : 40px;
                            padding-right   : 250px;
                            border-bottom   : 0;

                            @media #{$maxSmall} {
                                padding-left : 6px;
                                padding-right: 200px;
                            }
                        }

                        .btn {
                            position: absolute;
                            top     : 3px;
                            right   : 3px;

                            @media #{$maxSmall} {
                                width: 170px;
                            }
                        }
                    }
                }

                .form-action {
                    position    : relative;
                    width       : 600px;
                    margin-right: auto;
                    margin-left : auto;

                    @media screen and (max-width:576px) {
                        width: 300px;
                    }

                    @media screen and (min-width:578px) and (max-width:767px) {
                        width: 500px;
                    }

                    .input-group {
                        border          : none;
                        height          : 65px;
                        padding         : 7px;
                        border-radius   : 6px;
                        background-color: #f5f5f5;

                        .btn {
                            width        : 170px;
                            height       : 50px;
                            line-height  : 50px;
                            border-radius: 4px;

                            @media #{$maxSmall} {
                                width    : 120px;
                                font-size: 13px;
                            }
                        }
                    }

                    .form-control {
                        margin-bottom: 0;
                        border-radius: 0;
                        padding-left : 24px;
                        padding-right: 200px;
                        border-bottom: 0;
                        width        : calc(100% - 170px);
                        height       : 50px;

                        @media #{$maxSmall} {
                            padding-left : 15px;
                            padding-right: 10px;
                            font-size    : 13px;
                            width        : calc(100% - 120px);
                        }
                    }

                    .btn {
                        position: absolute;
                        top     : 7px;
                        right   : 7px;
                        z-index : 60;
                    }
                }
            }

        }

        .hero-image {
            overflow: visible;
        }
    }

    &.hero-light {
        .hero-cotainer {
            .hero-content {
                .hero-headline {
                    color: var(--global--color-white);
                }

                .hero-bio {
                    color: #f8f9fa;
                }

            }
        }
    }
}