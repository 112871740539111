$fontawesome: "Font Awesome 5 Free";

$baseline: 20px;
$line-height-base   : 1.5;
$line-height-content: 1.8;

$decoration-base: none;

$font-size-base: 13px;
$font-size-h1  : 52px;
$font-size-h2  : 42px;
$font-size-h3  : 38px;
$font-size-h4  : 32px;
$font-size-h5  : 24px;
$font-size-h6  : 18px;


$background-base     : no-repeat center center fixed;
$background-size-base: cover;


$radius-5 : 5px;
$radius-6 : 6px;
$radius-10: 10px;
$radius-50: 50%;

$maxSmall : "screen and (max-width:767px)";
$minTablet: "screen and (min-width:768px)";
$maxTablet: "screen and (max-width:991px)";
$minLarge : "screen and (min-width:992px)";
$maxLarge : "screen and (max-width:1200px)";
$minXlarge: "screen and (min-width:1200px)";
$noteBook : "(min-width:992px) and (max-width:1200px)";