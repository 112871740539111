/*-----------------------------------------------------------------------------------

	Theme Name : Magma
	Theme URI  : http://themeforest.net/user/zytheme
	Description: Magma is a pixel perfect creative HTML5 landing page  based on designed with great attention to details, flexibility and performance. It is ultra professional, smooth and sleek, with a clean modern layout.
	Author     : zytheme
	Author URI : http://themeforest.net/user/zytheme
	Version    : 1.0

-----------------------------------------------------------------------------------*/
/*!
// Contents
// ------------------------------------------------>

1.  Global Styles
2.  Typography
3.	Color
4.	Align
5.	Grid
6.	Background
7.	Buttons
8.	Forms
9.	Heading
10. Header
11. Menu
12. Clients
13. Hero
14. Services
15. Testimonial
16. Feature
17. Action
18. Pricing
19. Carousel
20. Landing

/*------------------------------------*\
    #GLOBAL STYLES
\*------------------------------------*/
/*
WARNING! DO NOT EDIT THIS FILE!

To make it easy to update your theme, you should not edit the styles in this file. Instead use 
the custom.css file to add your styles. You can copy a style from this file and paste it in 
custom.css and it will override the style in this file. You have been warned! :)
*/

:root {
    --global--font-body       : 'Roboto', sans-serif;
    --global--font-heading    : 'Jost', sans-serif;
    --global--color-primary   : #ff5421;
    --global--color-secondary : #f13700fc;
    --global--color-body      : #999999;
    --global--color-heading   : #333;
    --global--color-subheading: #fefeff;
    --global--color-white     : #fff;
    --global--color-gray      : #FAF8FB;
    --global--color-pink      : #FEFBFF;
}

body,
html {
    overflow-x: hidden;
}

html {
    font-size: $font-size-base;
}

body {
    background-color: var(--global--color-white);
    font-family     : var(--global--font-body);
    font-size       : $font-size-base;
    font-weight     : 400;
    color           : var(--global--color-body);
    line-height     : $line-height-base;
    margin          : 0;
}

.wrapper {
    background-color: var(--global--color-white);
}

* {
    outline: none;
}

::-moz-selection {
    text-shadow: none;
}

::selection {
    background-color: var(--global--color-primary);
    color           : var(--global--color-white);
    text-shadow     : none;
}

a {
    transition: 0.3s ease;
    color     : var(--global--color-primary);

    &:hover {
        color          : rgba(var(--global--color-primary), 10%);
        text-decoration: none;
    }

    &:focus {
        color          : rgba(var(--global--color-primary), 10%);
        outline        : none;
        text-decoration: none;
    }

    &:active {
        color          : rgba(var(--global--color-primary), 10%);
        outline        : none;
        text-decoration: none;
    }
}

hr {
    margin: 0;
}

textarea {
    resize: none;
}

button.btn {
    margin-bottom: 0;
}

section {
    background-color: var(--global--color-white);
}

.modal-backdrop {
    z-index         : 1020;
    background-color: rgba(34, 34, 34, 0.95);
}

.fullscreen {
    height: 100vh !important;
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
    .fullscreen {
        height: auto !important;
    }
}